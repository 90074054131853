/* eslint-disable eqeqeq */
import React, { FC, useState, useEffect } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Grid, Checkbox, Button, TextField, FormControlLabel, Link, MenuItem } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "material-ui";
import { Formik, Form } from "formik";
import { useSnackbar } from "notistack";
import axios from "axios";
import * as Yup from "yup";
import { GAEvent } from "../GoogleAnalytics";

const useStyles = makeStyles(() => ({
  holder: {
    borderRadius: "5px !important",
    //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
    //@ts-ignore
    boxShadow: ({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
    //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  dividerTitle: {
    backgroundColor: "#06141f",
    width: "33%",
    marginBottom: "0px",
    height: "3px",
    margin: "20px auto",
  },
  bold: {
    fontWeight: 700,
  },
  url: {
    color: "#7c7c7c",
    textDecoration: "none",
    "&:hover": {
      color: "#1f2532",
      textDecoration: "none",
    },
  },
  detailsParagraph: {
    textAlign: "left",
    fontSize: "1.8rem",
  },
  link: {
    backgroundColor: "transparent",
    marginBottom: "1rem",
    color: "#3f51b5",
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
    //fontWeight: '700',
    fontWeight: 700,
  },
  typoHeading: {
    textAlign: "center",
    color: "textPrimary",
    fontWeight: 500,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  button: {
    width: "100%",
    //@ts-ignore
    backgroundColor: ({ websiteColors }) => `${websiteColors?.accentColor || 'grey'}`,
    //@ts-ignore
    color: ({ websiteColors }) => `${websiteColors?.primaryColor || 'black'}`,
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
  },
  noSideDetails: {
    display: "flex",
    justifyContent: "center",
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);
interface Props {
  base_uri: string | null;
  motorGroupId: number;
  activeMake: string | null;
  activeMakeId: number;
  dealerList: Dealer[];
  activeDealerId?: number;
  masterDealerId?: number;
  dealerId?: number | null;
  defaultQuestions?: Question[];
  dealerName: string | null;
  paper?: boolean;
  dealerMakesOnly?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
  websiteColors?: any | null;
}

interface Dealer {
  dealerId: any;
  name: any;
  relatedBrands: any;
}

interface Question {
  name: string;
  labelComponent: any;
  correct: boolean;
  order: number;
  answer?: boolean;
}

const Service: FC<Props> = ({
  base_uri,
  motorGroupId,
  dealerId,
  activeMake,
  masterDealerId,
  activeDealerId,
  activeMakeId,
  dealerList,
  dealerName,
  paper,
  paperColor,
  paperShadowColor,
  dealerMakesOnly = false,
  websiteColors,
}) => {

  const defaultQuestions: Question[] = [
    {
      name: "contactMe",
      labelComponent: dealerName ? <span>I allow {dealerName} to contact me</span> : <span>I allow the dealership to contact me</span>,
      correct: true,
      order: 1
    },
    {
      name: "popi",
      labelComponent: (
        <span>
          By submitting this form I agree to the{" "}
          <Link component={RouterLink} to="/terms">terms and conditions</Link>{" "}
          and{" "}
          <Link component={RouterLink} to="/privacy">privacy policies</Link>
        </span>
      ),
      correct: true,
      order: 4
    }
  ];

  const classes = useStyles({ paper, paperColor, paperShadowColor, websiteColors });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [makes, setMakes] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [variants, setVariants] = useState<any>([]);
  const [dealers, setDealers] = useState<any>([]);
  const [questionAnswers, setQuestionAnswers] = useState<Question[]>(defaultQuestions);
  const [allAnswersChecked, setAllAnswersChecked] = useState(!defaultQuestions || defaultQuestions.length <= 0 ? true : false);
  const [delearName, setDelearName] = useState(undefined);
  const [delearDetails, setDelearDetails] = useState<any>(undefined);
  const isDealerProvided = dealerId && dealerId > 0;
  const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  useEffect(() => {
    let f = dealerList?.filter((d) => d.dealerId !== masterDealerId);
    // @ts-ignore
    setDelearName(f?.filter((d) => d.dealerId === activeDealerId)[0]?.name);

    if (dealerId !== masterDealerId) {
      setDelearDetails(dealerList?.filter((d) => d.dealerId == dealerId)[0]);
    }

    if (dealerList) {
      setDealers(dealerList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealerList, activeDealerId, dealerId]);

  const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    regNo: "",
    VIN: "",
    mileage: 0,
    make: activeMake ? activeMake : "",
    makeId: activeMakeId ? activeMakeId : "",
    model: "",
    modelId: 0,
    variant: "",
    variantId: 0,
    dealerId: delearDetails?.dealerId || activeDealerId || dealerId,
    dealer: delearDetails?.name || delearName || "",
    date: "",
    notes: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
    regNo: Yup.string().required("Registration number is required"),
    VIN: Yup.string().required("Registration number is required"),
    mileage: Yup.number().positive("Mileage cannot be negative").required("Mileage is required"),
    date: Yup.date().min(new Date(), "Service date cannot be before or on today").required("Service date is required"),
    make: Yup.string().required("Make selection is required"),
    model: Yup.string().required("Model selection is required"),
    variant: Yup.string().required("Variant selection is required"),
    dealer: Yup.string().required("Dealership selection is required")
  });

  useEffect(() => {
    Promise.allSettled([
      getMakes()
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, "Unexpected error occurred while loading form options"), { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMakes = async () => {
    setLoadingOptions(true);

    return await axios
      .get(`${base_uri}/Makes`)
      .then((result) => {
        if (activeMakeId) {
          //@ts-ignore
          setMakes(result?.data?.list.filter((r: any) => r.id == activeMakeId) || []);
        } else
          if (dealerMakesOnly) {
            const brandIDs: any = dealerList.reduce((ac: number[], v) => {
              const aBrands = v.relatedBrands;
              const flatBrands = aBrands.reduce((acc: number[], val: object | any) => {
                if (ac.includes(val.id)) {
                  return acc;
                }
                return [...acc, val.id];
              }, []);
              return [...ac, ...flatBrands];
            }, []);
            setMakes(result?.data?.list.filter((r: any) => brandIDs.includes(r.id)) || []);
          } else {
            setMakes(result?.data?.list || []);
          }
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, `Failed to load the makes: ${error}`), { variant: 'error' });
      })
      // always
      .then(() => {
        setLoadingOptions(false);
      });
  }

  const getModels = async (makeId: number) => {
    setLoadingOptions(true);

    return await axios // @ts-ignore
      .get(`${base_uri}/Models/unfiltered?makeId=${makeId}`)
      .then((result) => {
        setLoadingOptions(false);
        setModels(result?.data?.list || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(getErrorMessage(error, `Failed to load the models: ${error}`), { variant: 'error' });
      });
  }

  const getVariants = async (modelId: number) => {
    setLoadingOptions(true);

    return await axios // @ts-ignore
      .get(`${base_uri}/Variants?modelId=${modelId}&isService=true`)
      .then((result) => {
        setLoadingOptions(false);
        setVariants(result?.data?.list || []);
      })
      .catch((error) => {
        setLoadingOptions(false);
        enqueueSnackbar(getErrorMessage(error, `Failed to load the variants: ${error}`), { variant: 'error' });
      });
  }

  const getDealers = async (makeId: number | null = 0) => {
    if (makeId && makeId !== 0) {
      setLoadingOptions(true);
      // @ts-ignore
      const filteredDealers = dealerList?.reduce((ac, v) => {
        // @ts-ignore
        const included = v.relatedBrands.reduce((acc, va) => {
          if (va.id === makeId) {
            return true;
          }
          return acc;
        }, false)
        if (included) {
          return [...ac, v];
        }
        return ac;
      }, []);
      setDealers(filteredDealers);
      setLoadingOptions(false);
    } else {
      if (!dealerList || dealerList?.length === 0) {
        setLoadingOptions(true);
        let url = isDealerProvided
          ? `${base_uri}/Dealers/DealerContactDetails/${dealerId}`
          : `${base_uri}/Dealers/AllDealerContactDetails`;

        return await axios
          .get(url)
          .then((result) => {
            if (!result?.data) {
              enqueueSnackbar("No dealers found on server", { variant: 'error' });
              return;
            }
            let dList = [];
            if (Array.isArray(result.data)) {
              // Filter out the master dealer & the dealers from other motorgroups
              dList = result.data
                .filter((d: any) => !d.name.trim().toLowerCase().includes("master") && (!motorGroupId || motorGroupId < 0 || d.motorgroupId == motorGroupId))
                .map((d: any) => { return { dealerId: d.dealerId, name: d.name, relatedBrands: d.relatedBrands } })
                .sort((a, b) => a.name > b.name ? 1 : -1);
            } else {
              dList.push({ dealerId: result.data.dealerId === 0 ? dealerId : result.data.dealerId, name: result.data.name });
            }
            setLoadingOptions(false);
            setDealers(dList);
          })
          .catch((error) => {
            setLoadingOptions(false);
            enqueueSnackbar(getErrorMessage(error, `Failed to load the dealers: ${error}`), { variant: 'error' });
          });
      };
      if (dealerList && dealerList?.length > 0) {
        setDealers(dealerList);
      };
    }
  };

  const createLead = (values: any, setSubmitting: Function) => {
    setSubmitting(true);

    const pageTitle = document.getElementsByTagName("title")[0].innerHTML;
    GAEvent('form_submissions', 'form_submissions', 'serviceEnquiry_submission', 'xhr',
    {
      url: window.location.pathname,
      page_title: pageTitle,
      form_type: 'serviceEnquiry'
    });

    let params = {
      leadSourceId: 1,
      leadTypeId: 5,      // Service
      typeCode: "NEW",
      dealerId: values.dealerId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phone,
      emailAddress: values.email,
      notes: values.notes,
      regNo: values.regNo,
      VIN: values.VIN,
      mileage: values.mileage,
      serviceDate: values.date,
      modelId: values.modelId,
      variantId: values.variantId
    };
    // @ts-ignore
    axios.post(`${base_uri}/leads`, params).then((response) => {
      setSubmitting(false);
      if (!response?.data?.id) {
        enqueueSnackbar("An unexpected error occurred while processing the information, cannot continue", { variant: "error" });
      } else {
        enqueueSnackbar(`Successfully created and processed the information`, { variant: "success" });
      }
    }).catch((error) => {
      // TODO: REMOVE THIS SHIT
      enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
      // enqueueSnackbar("Unable to complete the request", { variant: 'error' });
      setSubmitting(false);
    });
  }

  const getErrorMessage = (axiosError: any, defaultMsg: string) => {
    let errorTitle = defaultMsg;
    let errorDetails = "No additional details are available";

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = "Failed to send the request";
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.detail ?? errorDetails;
    }
    return `${errorTitle}: ${errorDetails}`;
  }

  const toggleAnswer = (question: Question) => {
    let tempAnswersList = questionAnswers;
    let answerIndex = tempAnswersList.findIndex((itm: Question) => itm.name === question.name);

    if (answerIndex >= 0) {
      let existingAnswer = tempAnswersList[answerIndex];
      existingAnswer.answer = !existingAnswer.answer;
      setQuestionAnswers(tempAnswersList);
      checkAnswersValid(tempAnswersList);
    }
  }

  const checkAnswersValid = (answers: Question[]) => {
    let validAnswers = true;

    answers.forEach(question => {
      if (!question.answer) {
        validAnswers = false;
      }
    });

    setAllAnswersChecked(validAnswers);
  };

  const handleSelectChange = (e: any, value: any, setFieldValue: Function, fieldName: any, fieldId: any) => {
    setFieldValue(fieldName, e.target.value);
    setFieldValue(fieldId, value.props.id);
  };

  const handleMakeChange = (e: any, value: any, setFieldValue: Function) => {
    handleSelectChange(e, value, setFieldValue, "make", "makeId");

    // Reset the Make & Variant selections
    setFieldValue("model", "");
    setFieldValue("modelId", 0);
    setFieldValue("variant", "");
    setFieldValue("variantId", 0);

    // Load the models for the selected make
    setTimeout(() => {
      getModels(value?.props?.id);
      if (dealerList.length > 1 && masterDealerId === activeDealerId) {
        getDealers(value?.props?.id);
      }
    }, 50);
  }

  useEffect(() => {
    if (activeMakeId) {
      getModels(activeMakeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMakeId])

  useEffect(() => {
    if (makes?.length === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeMakeId = makes[0].id;
    };
  }, [makes])

  const handleModelChange = (e: any, value: any, setFieldValue: Function) => {
    handleSelectChange(e, value, setFieldValue, "model", "modelId");

    // Reset the Variant selection
    setFieldValue("variant", "");
    setFieldValue("variantId", 0);

    // Load the variants for the selected model
    setTimeout(() => {
      getVariants(value?.props?.id);
    }, 50);
  }

  return (
    <React.Fragment>
      <div className={classes.holder}>
        <MuiThemeProvider>
          <Grid container direction="row" justifyContent="center">
            {
              loading &&
              <CircularProgress />
            }
            <div className={classes.holderInner}>
              {
                !loading &&
                <Formik
                  initialValues={initialFormValues}
                  isInitialValid={false}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    createLead(values, actions.setSubmitting);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    } = props;

                    return (
                      <Form noValidate>
                        <Grid container spacing={2} direction="row">
                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="firstName"
                              label="First Name"
                              variant="outlined"
                              value={values.firstName}
                              disabled={isSubmitting}
                              helperText={touched.firstName ? errors.firstName : ""}
                              error={touched.firstName && Boolean(errors.firstName)}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="lastName"
                              label="Last Name"
                              variant="outlined"
                              value={values.lastName}
                              disabled={isSubmitting}
                              helperText={touched.lastName ? errors.lastName : ""}
                              error={touched.lastName && Boolean(errors.lastName)}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="email"
                              label="Email"
                              type="email"
                              variant="outlined"
                              value={values.email}
                              disabled={isSubmitting}
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="phone"
                              label="Cellphone"
                              type="tel"
                              variant="outlined"
                              value={values.phone}
                              disabled={isSubmitting}
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="regNo"
                              label="Registration Number"
                              variant="outlined"
                              value={values.regNo}
                              disabled={isSubmitting}
                              helperText={touched.regNo ? errors.regNo : ""}
                              error={touched.regNo && Boolean(errors.regNo)}
                              onChange={handleChange}
                              inputProps={{ style: { textTransform: 'uppercase' } }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="VIN"
                              label="VIN Number"
                              variant="outlined"
                              value={values.VIN}
                              disabled={isSubmitting}
                              helperText={touched.VIN ? errors.VIN : ""}
                              error={touched.VIN && Boolean(errors.VIN)}
                              onChange={handleChange}
                              inputProps={{ style: { textTransform: 'uppercase' } }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="mileage"
                              label="Mileage"
                              type="number"
                              variant="outlined"
                              value={values.mileage}
                              disabled={isSubmitting}
                              helperText={touched.mileage ? errors.mileage : ""}
                              error={touched.mileage && Boolean(errors.mileage)}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              fullWidth
                              required
                              id="date"
                              label="Preferred Service Date"
                              type="date"
                              variant="outlined"
                              value={values.date}
                              disabled={isSubmitting}
                              helperText={touched.date ? errors.date : ""}
                              error={touched.date && Boolean(errors.date)}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              select
                              fullWidth
                              required
                              id="make"
                              label="Make"
                              variant="outlined"
                              value={makes.length === 1 ? makes[0].name : values.make}
                              disabled={isSubmitting || (!values?.makeId && loadingOptions)
                                || makes.length === 1
                                //  || activeMake !== null || activeMakeId !== null 
                              }
                              helperText={errors.make && touched.make ? errors.make : ""}
                              error={touched.make && Boolean(errors.make)}
                              onBlur={handleBlur("make")}
                              //@ts-ignore
                              onChange={(e: any, child: any) => {
                                handleMakeChange(e, child, setFieldValue);
                              }}
                            >
                              {makes.map((option: any) => (
                                <MenuItem key={option.id} id={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </CssTextField>
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              select
                              fullWidth
                              required
                              id="model"
                              label="Model"
                              variant="outlined"
                              value={values.model}
                              disabled={isSubmitting || (!values?.modelId && loadingOptions) || !values?.makeId}
                              helperText={errors.model && touched.model ? errors.model : ""}
                              error={touched.model && Boolean(errors.model)}
                              onBlur={handleBlur("model")}
                              //@ts-ignore
                              onChange={(e: any, child: any) => {
                                handleModelChange(e, child, setFieldValue);
                              }}
                            >
                              {models.map((option: any) => (
                                <MenuItem key={option.id} id={option.id} value={option.title}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </CssTextField>
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              select
                              fullWidth
                              required
                              id="variant"
                              label="Variant"
                              variant="outlined"
                              value={values.variant}
                              disabled={isSubmitting || loadingOptions || !values?.modelId || variants.length === 0}
                              helperText={errors.variant && touched.variant ? errors.variant : ""}
                              error={touched.variant && Boolean(errors.variant)}
                              onBlur={handleBlur("variant")}
                              //@ts-ignore
                              onChange={(e: any, child: any) => {
                                handleSelectChange(e, child, setFieldValue, "variant", "variantId");
                              }}
                            >
                              {variants.map((option: any) => (
                                <MenuItem key={option.id} id={option.id} value={option.variantName}>
                                  {option.variantName}
                                </MenuItem>
                              ))}
                            </CssTextField>
                          </Grid>

                          <Grid item xs={12} md={6} lg={4}>
                            <CssTextField
                              select={!(dealerName && delearDetails)}
                              fullWidth
                              required
                              id="dealer"
                              label="Dealership"
                              variant="outlined"
                              value={values.dealer}
                              disabled={isSubmitting || !values.make}
                              //@ts-ignore
                              helperText={errors.dealer && touched.dealer ? errors.dealer : ""}
                              error={touched.dealer && Boolean(errors.dealer)}
                              onBlur={handleBlur("dealer")}
                              //@ts-ignore
                              onChange={(e: any, child: any) => {
                                handleSelectChange(e, child, setFieldValue, "dealer", "dealerId");
                              }}
                              inputProps={{ readOnly: dealerName && delearDetails }}
                            >
                              {
                                // @ts-ignore
                                dealers?.map((option) => (
                                  <MenuItem key={option.dealerId} id={option.dealerId} value={option.name}>
                                    {option.name}
                                  </MenuItem>
                                ))
                              }
                            </CssTextField>
                          </Grid>

                          <Grid item xs={12}>
                            <CssTextField
                              multiline
                              rows={4}
                              fullWidth
                              id="notes"
                              label="Notes and Remarks"
                              variant="outlined"
                              value={values.notes}
                              disabled={isSubmitting}
                              helperText={touched.notes ? errors.notes : ""}
                              error={touched.notes && Boolean(errors.notes)}
                              onChange={handleChange}
                            />
                          </Grid>

                          {
                            defaultQuestions &&
                            defaultQuestions.map((itm: Question, index: number) => (
                              <Grid item xs={12} md={6} key={index} style={{ alignItems: 'center' }}>
                                <FormControlLabel
                                  label={itm.labelComponent}
                                  control={
                                    <Checkbox
                                      name={itm.name}
                                      disabled={isSubmitting}
                                      color="primary"
                                      onClick={() => {
                                        toggleAnswer(itm);
                                      }}
                                    />
                                  }
                                />
                              </Grid>
                            ))
                          }

                          {/* Form action buttons */}
                          <Grid item xs={12} className={classes.stepPaddingButton}>
                            <Button
                              className={classes.button}
                              variant="contained"
                              type="submit"
                              disabled={!allAnswersChecked || isSubmitting}
                            >
                              {
                                loading || isSubmitting
                                  ? <CircularProgress />
                                  : "Contact Me"
                              }
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              }
            </div>
          </Grid>
        </MuiThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default Service;
