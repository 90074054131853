import ReactGA from "react-ga4";

export const GAInit = (GTMID: string, testMode: boolean = false, gtagUrl: string = 'https://www.googletagmanager.com/gtag/js') => {
  const options = {
    testMode,
    gtagUrl
  };

  ReactGA.initialize([
    {
      trackingId: GTMID,
    }
  ], options);
};

export const GAEvent = (action: string = 'event', category: string = 'category', label: string, transport: ('beacon' | 'xhr' | 'image'), params = {}) => {
  ReactGA.event({
    action,
    category,
    label,
    transport
  }, params);
};

export const GAPageView = (pagePath: string = '/', pageTitle: string = '') => {
  ReactGA.send({ hitType: "pageview", page: pagePath, title: pageTitle });
};