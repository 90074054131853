/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import { Switch } from 'formik-material-ui'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from '@material-ui/core/Box';
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import MenuItem from "@material-ui/core/MenuItem";
import { GAEvent } from "../GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
    //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
    //@ts-ignore
    boxShadow: ({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
    //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%"
  },
  flex: {
    display: "flex"
  },
  button: {
    width: "100%",
    //@ts-ignore
    backgroundColor: ({ websiteColors }) => `${websiteColors?.accentColor || 'grey'}`,
    //@ts-ignore
    color: ({ websiteColors }) => `${websiteColors?.primaryColor || 'black'}`,
  },
  link: {
    /* @ts-ignore */
    color: ({ primaryColor }) => `${primaryColor}!important`,
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: 'uppercase'
  },
}));

interface Props {
  base_uri: string,
  dealerName: string,
  note: string,
  dealerId: number,
  activeDealerId?: number;
  masterDealerId?: number;
  dealerType?: "single_dealer" | "multi_dealer" | "multi_region_dealer",
  dealers?: Dealer[];
  isCashBuyer?: boolean,
  leadTypeId: number,
  websiteColors?: any;
  variantId: number;
  stockId?: number | undefined;
  floorCode?: string | undefined;
  sourceCode?: string | undefined;
  onLeadSubmitted?: Function;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
  offerId?: number | undefined;
  leadSourceId?: number | undefined;
}

interface Dealer {
  dealerId: any;
  name: any;
}

const ContactUs: FC<Props> = ({
  base_uri,
  dealerId,
  dealerName,
  activeDealerId,
  masterDealerId,
  dealerType = "single_dealer",
  dealers,
  isCashBuyer,
  leadTypeId,
  websiteColors,
  variantId,
  note,
  stockId,
  floorCode,
  sourceCode,
  onLeadSubmitted,
  paper,
  paperColor,
  paperShadowColor,
  offerId,
  leadSourceId,
}) => {

  const classes: any = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });
  const [contactMe, setContactMe] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false)
  const [delearName, setDelearName] = useState(undefined);
  const [delearDetails, setDelearDetails] = useState<any>(undefined);
  let phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
    },
  })(TextField);

  useEffect(() => {
    let f = dealers?.filter((d) => d.dealerId !== masterDealerId);
    setDelearName(f?.filter((d) => d.dealerId === activeDealerId)[0]?.name);

    if (dealerId !== masterDealerId) {
      setDelearDetails(dealers?.filter((d) => d.dealerId == dealerId)[0]);
    }
  }, [])

  const values = {
    name: '',
    phone: '',
    email: '',
    dealerId: delearDetails?.dealerId || activeDealerId || dealerId || '',
    dealer: delearDetails?.name || delearName || '',
    notes: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(
      "Name is required"
    ),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
  });

  const checkValid = (validForm: boolean) => {
    return (validForm && contactMe)
  }

  const createVehicleLead = (values: any) => {
    let vehicleParam: any = {}
    vehicleParam.LeadId = values.leadId;
    vehicleParam.VariantId = variantId;
    // @ts-ignore
    axios.post(`${base_uri}/leadVehicles`, vehicleParam
    ).then((responseSecond) => {
      setLoader(false);
      enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
    }).catch(error => {
      enqueueSnackbar("Unable to get complete the request", { variant: 'error' });
      //setLoader(false);
    })
  }

  const createLead = (values: any) => {
    setLoader(true);

    const pageTitle = document.getElementsByTagName("title")[0].innerHTML;
    GAEvent('form_submissions', 'form_submissions', 'contactUs_submission', 'xhr',
    {
      url: window.location.pathname,
      page_title: pageTitle,
      form_type: 'contactUs'
    });

    let params: any = {};
    params.leadStatusId = 1;
    params.dealerId = values.dealerId || dealerId;
    params.name = values.name;
    params.firstName = values.name;
    params.lastName = '';
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = leadSourceId ? leadSourceId : 1;
    params.notes = isCashBuyer ? `Cash buyer + ${values.notes}` : values.notes;
    params.leadTypeId = leadTypeId;
    params.dateUpdated = new Date().toISOString();
    params.variantId = variantId;
    params.offerId = offerId;

    if (offerId) {
      params.offerId = offerId;
    };

    if (stockId) {
      params.stockId = stockId;
    };

    // FLOORCODE AND SOURCECODE WAS JOINED IN ONE IF_STATEMENT WITH AN && OPERATOR. FOR RENAULT SELECTION, I SPLIT THEM UP.

    if ((floorCode || "").length > 0) {
      params.floorCode = floorCode;
    };

    if ((sourceCode || "").length > 0) {
      params.sourceCode = sourceCode;
    };

    // @ts-ignore
    axios.post(`${base_uri}/leads`, params).then((response) => {
      // @ts-ignore
      fbq('track', 'Lead');  // eslint-disable-line

      if (variantId) {
        values.leadId = response.data.id;
        createVehicleLead(values);
      }
      else {
        setLoader(false);
        enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
        if (onLeadSubmitted) onLeadSubmitted(response?.data?.id);
      }
    }).catch(error => {
      // TODO: REMOVE THIS SHIT
      enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
      // enqueueSnackbar("Unable to complete the request", { variant: 'error' });
      setLoader(false);
    })
  }

  const handleSelectChange = (e: any, value: any, setFieldValue: any, fieldName: any, fieldId: any) => {
    setFieldValue(fieldName, e.target.value)
    setFieldValue(fieldId, value.props.id)
  }

  return (
    <React.Fragment>
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div className={classes.holder}>
              <div className={classes.holderInner}>
                <Formik
                  isInitialValid={false}
                  initialValues={values}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    createLead(values)
                  }}
                >
                  {(props: any) => {
                    const {
                      values,
                      touched,
                      errors,
                      isValid,
                      handleBlur,
                      submitForm,
                      setFieldTouched,
                      setFieldValue,
                    } = props;
                    return (
                      <Form>
                        <Grid container className={classes.root} direction="row">
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                          >
                            <CssTextField
                              id="name"
                              name="name"
                              label="Name"
                              value={values.name}
                              fullWidth
                              variant="outlined"
                              helperText={touched.name ? errors.name : ""}
                              error={touched.name && Boolean(errors.name)}
                              onChange={(event) => {
                                setFieldValue("name", event.target.value)
                                setFieldTouched("name", true, false);
                              }}
                            />
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <CssTextField
                              id="email"
                              name="email"
                              type="email"
                              label="Email"
                              fullWidth
                              value={values.email}
                              variant="outlined"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              onChange={(event) => {
                                setFieldValue("email", event.target.value)
                                setFieldTouched("email", true, false);
                              }}
                            />{" "}
                          </Grid>
                          <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                            lg={12}>
                            <CssTextField
                              id=" phone"
                              name="phone"
                              type="tel"
                              label="Cellphone"
                              fullWidth
                              value={values.phone}
                              variant="outlined"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              onChange={(event) => {
                                setFieldValue("phone", event.target.value)
                                setFieldTouched("phone", true, false);
                              }}
                            />
                          </Grid>
                          {dealerType == "multi_dealer" &&
                            <Grid item className={classes.stepPaddingButton} xs={12}>
                              <CssTextField
                                variant="outlined"
                                fullWidth
                                id="dealer"
                                select={
                                  delearName !== undefined
                                    ? false
                                    : true && delearDetails !== undefined
                                      ? false
                                      : true
                                }
                                //required
                                label="Dealership"
                                value={values.dealer}
                                helperText={errors.dealer && touched.dealer ? errors.dealer : ''}
                                /* @ts-ignore */
                                error={errors.dealer && touched.dealer}
                                onBlur={handleBlur("dealer")}
                                /* @ts-ignore */
                                onChange={(e, child) => handleSelectChange(e, child, setFieldValue, 'dealer', 'dealerId')}
                              >
                                {dealers && dealers.map((option: any) => (
                                  <MenuItem
                                    key={option.dealerId}
                                    id={option.dealerId}
                                    value={option.name}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </CssTextField>
                            </Grid>
                          }
                          {note &&
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12} lg={12}>
                              <Box mb={2}>
                                <CssTextField
                                  id="notes"
                                  name="notes"
                                  placeholder="leave a message"
                                  type="text"
                                  fullWidth
                                  multiline
                                  rows={4}
                                  variant="standard"
                                  helperText={touched.notes ? errors.notes : ""}
                                  error={touched.notes && Boolean(errors.notes)}
                                  onChange={(event) => {
                                    setFieldValue("notes", event.target.value)
                                    setFieldTouched("notes", true, false);
                                  }}
                                />
                              </Box>
                            </Grid>
                          }

                          <Grid item className={classes.flex} xs={12} md={12}
                            lg={12} style={{ alignItems: 'center' }}>
                            {/* @ts-ignore */}
                            <FormControlLabel
                              control={
                                <Field component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  name="contactMe"
                                  onClick={() => {
                                    setContactMe(contactMe ? false : true)
                                  }}
                                />
                              }

                            />
                            <span>{dealerName ? `I allow ${dealerName} to contact me` : `I allow the dealership to contact me`}</span>
                          </Grid>

                          <Grid item className={classes.flex} xs={12} md={12}
                            lg={12} style={{ alignItems: 'center' }}>
                            {/* @ts-ignore */}
                            <FormControlLabel
                              control={
                                <Field component={Switch}
                                  color="primary"
                                  type="checkbox"
                                  name="popi"
                                />
                              }
                            />
                            <span>
                              By submitting this form I agree to the <Link component={RouterLink} to="/terms" className={classes.link}>terms and conditions</Link> and <Link component={RouterLink} to="/privacy" className={classes.link}>privacy policies.</Link>
                            </span>
                          </Grid>

                          <Grid container direction="row">
                            <Grid item className={classes.stepPaddingButton} xs={12} md={12}
                              lg={12}>
                              {!loader &&
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={!checkValid(isValid)}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                >
                                  {isCashBuyer ? 'Order Now' : 'Contact Me'}
                                </Button>
                              }
                              {loader == true && (
                                <Button className={classes.button} variant={"contained"}
                                  color="primary"
                                  disabled={true}
                                >
                                  <CircularProgress />
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default ContactUs