import React, { useState, FC, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardHeader,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { defaultCipherList } from "constants";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
        //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
      //@ts-ignore
    boxShadow:({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
       //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ secondaryColor }) => `${secondaryColor} !important`,
      opacity: 0.8,
    },
  },
  input:{
    margin:"10px 0px"
  },
  inputDealer:{
    marginBottom:"10px" 
  }
}));

interface Props {
  personalDetails: any;
  onSubmit: Function;
  websiteColors?: any;
  setAtiveDealerId: Function;
  dealerList?: any[];
  defaultDealerId: any;
  rightSection?: any;
  wePay4Cars_uri: string | null;
  includeAvailability?: boolean;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
}

const PersonalDetailsForm: FC<Props> = ({
  personalDetails,
  onSubmit,
  dealerList,
  defaultDealerId,
  setAtiveDealerId,
  rightSection,
  websiteColors,
  wePay4Cars_uri,
  includeAvailability,
  paper,
  paperColor,
  paperShadowColor,
}) => {
  const classes = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });
  const localStyles = useStyles();
  const isDealerListAvailable = dealerList?.length && dealerList.length > 0;
  const defaultDealer = isDealerListAvailable
    ? dealerList?.find((d) => {
        return d?.dealerId === defaultDealerId;
      })
    : null;

  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState<any[]>([]);
  const [dealer, setDealer] = useState(defaultDealer);
  const [openLocation, setOpenLocation] = React.useState(false);

  const initialFormValues = {
    firstName: personalDetails?.firstName || "",
    lastName: personalDetails?.lastName || "",
    mobileNumber: personalDetails?.mobileNumber || "",
    emailAddress: personalDetails?.emailAddress || "",
    city: personalDetails?.city || "",
    province: personalDetails?.province || "",
    country: personalDetails?.country || "",
    availability: undefined,
  };

  const availabilityOptions = [
    { id: 1, name: "Now" },
    { id: 2, name: "In a week" },
  ];

  const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    emailAddress: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
  });

  useEffect(() => {
    const getLocations = async () => {
      let tempLocations: any[] = [];

      try {
        // @ts-ignore
        let locationsResult = await axios.get(
          `${wePay4Cars_uri}/dashboard/location`
        );

        tempLocations = locationsResult?.data;

        if (!tempLocations || tempLocations.length <= 0) {
          throw new Error();
        }
      } catch {
        tempLocations = [
          {
            city: "None available",
            province: "None available",
            country: "None available",
          },
        ];
      }

      setLocations(tempLocations);
      setLoading(false);
    };

    getLocations();
  }, []);

  const submitForm = (values: any) => {
    onSubmit(values);
  };

  const dealers = dealerList ? dealerList : [];

  const handleLocationChange = (
    e: any,
    value: any,
    setFieldValue: Function
  ) => {
    setFieldValue("city", value.city);
    setFieldValue("province", value.province);
    setFieldValue("country", value.country);
  };

  return (
    <React.Fragment>
      <div className={classes.holder}>
        <CardHeader
          className="p-4"
          title={"About you"}
          subheader={
            "In order to load your car we need to know a bit about you. This process will take under 5mins from start to completion."
          }
        />

<div className={classes.holderInner}>
          <Formik
            isInitialValid={false}
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isValid,
                handleBlur,
                handleChange,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <Grid item xs={12} md={4}>
                    {isDealerListAvailable === true && (
                      <TextField
                        fullWidth
                        label="Select a dealer"
                        required
                        select
                        className={classes.inputDealer}
                        value={dealer}
                        onChange={(e) => {
                          handleChange(e);
                          const value = e.target.value;
                          //@ts-ignore
                          setAtiveDealerId(value?.dealerId);
                          setDealer(value);
                        }}
                        onBlur={handleBlur}
                        name="activeDealer"
                        id="activeDealer"
                      >
                        {dealers.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.input}
                        value={values.firstName}
                        helperText={<>{touched.firstName ? errors.firstName : ""}</>}
                        error={touched.firstName && Boolean(errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.input}
                        value={values.lastName}
                        helperText={<>{touched.lastName ? errors.lastName : ""}</>}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="mobileNumber"
                        name="mobileNumber"
                        label="Mobile Number"
                        variant="outlined"
                        required
                        className={classes.input}
                        fullWidth
                        value={values.mobileNumber}
                        helperText={<>{touched.mobileNumber ? errors.mobileNumber : ""}</>}
                        error={
                          touched.mobileNumber && Boolean(errors.mobileNumber)
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <TextField
                        id="emailAddress"
                        name="emailAddress"
                        label="Email Address"
                        variant="outlined"
                        fullWidth
                        className={classes.input}
                        required
                        value={values.emailAddress}
                        helperText={<>{touched.emailAddress ? errors.emailAddress : ""}</>}
                        error={touched.emailAddress && Boolean(errors.emailAddress)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      {/* Location component copied from Mitsubishi OnDemand */}
                      <Autocomplete
                        id="location"
                        open={openLocation}
                        onOpen={() => {
                          setOpenLocation(true);
                        }}
                        onClose={() => {
                          setOpenLocation(false);
                        }}
                        getOptionSelected={(option: any, value: any) =>
                          option.city === value.city
                        }
                        getOptionLabel={(option: any) => option.city}
                        options={locations}
                        loading={loading}
                        onBlur={handleBlur}
                        onChange={(e: any, c: any) =>
                          handleLocationChange(e, c, setFieldValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Location"
                            variant="outlined" 
                            fullWidth
                            className={classes.input}
                            helperText={<>{touched.city ? errors.city : "Please enter your location"}</>}
                            error={touched.city && Boolean(errors.city)}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />

                      {includeAvailability && (
                        <TextField
                          id="availability"
                          name="availability"
                          label="When would you like to sell your vehicle?"
                          variant="outlined"
                          fullWidth
                          select
                          className={classes.input}
                          required
                          value={values.availability}
                          helperText={
                            touched.availability ? errors.availability : ""
                          }
                          error={
                            touched.availability && Boolean(errors.availability)
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {availabilityOptions.map((option: any) => (
                            <MenuItem
                              key={option.id}
                              id={option.id}
                              value={option.name}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>

                    {rightSection && (
                      <Grid item xs={12} sm={6} md={4}>
                        {rightSection}
                      </Grid>
                    )}

                    <Grid item xs={12} container justify="flex-end">
                      <Button
                        className={classes.btn}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalDetailsForm;
