import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  MenuItem,
  Menu,
  InputLabel,
  Slider,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { CurrencyValue } from '../TextMask';

const useStyle = makeStyles((theme) => ({
  button: {
    padding: "2px",
    minWidth: 0,
    "& .MuiButton-endIcon": {
      padding: 0,
      margin: 0,
    },
  },
  buttonLeft: {
    margin: "0 10px 0 0",
    //@ts-ignore
    color: ({ selectColor }) => `${selectColor ? selectColor : "black"} !important`,
  },
  buttonRight: {
    margin: "0 0 0 10px",
      //@ts-ignore
    color: ({ selectColor }) => `${selectColor ? selectColor : "black"} !important`,
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  rangeLabel: {
    padding: "2px",
    //@ts-ignore
    color: ({ labelColor }) => `${labelColor ? labelColor : "black"} !important`,
    //@ts-ignore
    background: ({ background }) => `${background ? background : "white"} !important`,
    zIndex: 10,
    minWidth: "fit-content",
  },
  rangeInputLeft: {
    minWidth: "100px",
    padding: 0,
    margin: 0,
    fontSize: "0.9rem",
    "& .MuiInputBase-input": {
      padding: 0,
      margin: 0,
      width: "40px",
      textAlign: "left",
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  rangeInputRight: {
    padding: 0,
    margin: 0,
    textAlign: "right",
    fontSize: "0.9rem",
    "& .MuiInputBase-input": {
      padding: 0,
      margin: 0,
      width: "40px",
      textAlign: "right",
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
  rangeInputLabel: {
    textTransform: "uppercase",
    whiteSpace: "nowrap"
  },
  slider:{
    //@ts-ignore
    color: ({ selectColor }) => `${selectColor ? selectColor : "blue"} !important`,
  }
}));

interface SelectionButtonProps {
  side: "left" | "right";
  value: number;
  options: number[];
  websiteColors?: any;
  rangeName: string;
  onChange: (val: number) => void;
  isCurrencyVal?: boolean;
}
const SelectionButton: FC<SelectionButtonProps> = ({
  side,
  value,
  websiteColors,
  options,
  onChange,
  rangeName,
  isCurrencyVal = false
}) => {
  const classes = useStyle({...websiteColors});

  const [val, setVal] = React.useState<number>(value);

  useEffect(() => {
    onChange(val);
  }, [val]);

  const handleChange = (val: any) => {
    setVal(val);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={`${rangeName}_${side}_range_button`}
        className={`${classes.button} ${
          side === "left" ? classes.buttonLeft : classes.buttonRight
        }`}
        aria-controls={`${rangeName}_${side}_range_menu`}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        endIcon={
          Boolean(anchorEl) == true ? (
            <ArrowDropUp fontSize="small" />
          ) : (
            <ArrowDropDown fontSize="small" />
          )
        }
      >
        {
          isCurrencyVal === true
          ? <span className={classes.rangeInputLabel}><CurrencyValue value={value} /></span>
          : value
        }
      </Button>
      <Menu
        id={`${rangeName}_${side}_range_menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((o) => (
          <MenuItem
            key={`range_selection_option_${rangeName}_${o}`}
            className={o === value ? classes.selected : undefined}
            dense
            onClick={() => handleChange(o)}
          >
            {
              isCurrencyVal === true
              ? <span className={classes.rangeInputLabel}><CurrencyValue value={o} /></span>
              : o
            }
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export interface FilterRangeProps {
  start: number;
  end: number;
  interval: number;
}

interface RangeSliderProps {
  id: string;
  label: string;
  websiteColors?: any;
  range: FilterRangeProps;
  reset: boolean;
  onChange: (range: number[]) => void;
  isCurrencyVal?: boolean;
}

export const RangeSlider: FC<RangeSliderProps> = ({ id, range, onChange, label,websiteColors, reset = false, isCurrencyVal = false }) => {
  const classes = useStyle({...websiteColors});

  const getRange = (cfg: FilterRangeProps): number[] => {
    let rangeArr = [];
    let { start, end, interval } = cfg;
    let step = start < end ? interval : -1;

    if (step > 0) {
      for (let i = start; i <= end; i += step) {
        rangeArr.push(Math.round(i / interval) * interval);
      }
    } else {
      for (let i = start; i >= end; i += step) {
        rangeArr.push(Math.round(i / interval) * interval);
      }
    }

    return rangeArr;
  };

  const options = useMemo(() => getRange({ ...range }), [range]);

  const [selectedTempRange, setSelectedTempRange] = useState<number[]>([
    range.start,
    range.end,
  ]);
  const [selectedRange, setSelectedRange] = useState<number[]>([
    range.start,
    range.end,
  ]);

  useEffect(() => {
    onChange(selectedRange);
  }, [selectedRange]);

  useEffect(()=> {
    if(reset){
      setSelectedTempRange([
        range.start,
        range.end,
      ])
      setSelectedRange([
        range.start,
        range.end,
      ])
    }
  }, [reset])

  return options && options.length > 0 ? (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      key={`${id}_rangeSelection`}
    >
      <Grid item style={{ height: "14px" }}>
        <Grid item xs container>
          <InputLabel id={`${id}_rangeSelection`} className={classes.rangeLabel}>
            {label}
          </InputLabel>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="row"
        wrap="nowrap"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs container justifyContent="flex-end" className={classes.rangeInputLeft}>
          <SelectionButton
            side="left"
            rangeName={"years"}
            options={options}
            websiteColors={websiteColors}
            value={selectedTempRange[0]}
            isCurrencyVal={isCurrencyVal}
            onChange={(val: number) => {
              if (selectedRange[0] !== val){
                setSelectedTempRange([val, selectedRange[1]]);
                setSelectedRange([val, selectedRange[1]]);}
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            value={selectedTempRange}
            onChange={(evt, val: any) => setSelectedTempRange(val)}
            onChangeCommitted={(evt, val: any) => {
              if(val[0] !== selectedRange[0] || val[1] !== selectedRange[1]) setSelectedRange(val);
            }}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
            className={classes.slider}
            step={range.interval}
            marks
            min={options[0]}
            max={options[options.length - 1]}
            
          />
        </Grid>
        <Grid item xs container justifyContent="flex-start">
          <SelectionButton
            side="right"
            rangeName={"years"}
            options={options}
            websiteColors={websiteColors}
            value={selectedTempRange[1]}
            isCurrencyVal={isCurrencyVal}
            onChange={(val: number) => {
              if (selectedRange[1] !== val){
                setSelectedTempRange([selectedRange[0], val]);
                setSelectedRange([selectedRange[0], val]);}
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
