import MaskedInput, { conformToMask } from 'react-text-mask'
import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

function TextMaskCustom(props: any) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={[/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export default TextMaskCustom;


const CurrencyMask = {
    prefix: 'R ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: true,
}

export const CurrencyValue = (props: any) => {

    const { value = 0, roundVal = false, padding } = props;
    const currencyMask = createNumberMask({
      ...CurrencyMask
    })
  
    try {
  
      const roundedNumber = (roundVal === true ? value?.toFixed() : value) || 0;
      const roundedNumberString = roundedNumber.toString();
      const mask = currencyMask(roundedNumberString);
      const filteredMask = mask.filter((val: any) => val != "[]");
  
      const convertedCurrency = conformToMask(
        roundedNumberString,
        filteredMask,
        {}
      );
  
      return convertedCurrency?.conformedValue ?? null;
  
    } catch {
      
      return value;
  
    }
  }
  
export const CurrencyInput = (props: any) => {
    const { inputRef, ...other } = props;
    const currencyMask = createNumberMask({
        ...CurrencyMask
    })

    return <MaskedInput mask={currencyMask}
        ref={(ref: any) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        {...other} />

}

export const CurrencyConverter = (amount: any) => {
    if (amount && typeof amount === 'string') {
        let removeCurrency = amount.replace(/R/g, '')
        let removeSpaces = removeCurrency.replace(/\s+/g, '')
        return parseFloat(removeSpaces)
    } else {
        return amount
    }
}
