/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import { CircularProgress } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { NavLink as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import { GAEvent } from "../GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
    //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
    //@ts-ignore
    boxShadow: ({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
    //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  stepPadding: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: "2rem",
  },
  stepPaddingBelow: {
    marginRight: "0.5rem",
    marginTop: "0.5rem",
  },
  stepPaddingButton: {
    marginTop: "0.5rem",
  },
  stepPaddingTitle: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  card: {
    width: "100%",
  },
  flex: {
    display: "flex",
  },
  button: {
    width: "100%",
    //@ts-ignore
    backgroundColor: ({ websiteColors }) => `${websiteColors?.accentColor || 'grey'}`,
    //@ts-ignore
    color: ({ websiteColors }) => `${websiteColors?.primaryColor || 'black'}`,
  },
  backButton: {
    marginTop: "2rem",
  },
  image: {
    minWidth: "100%",
    height: 550,
  },
  uppercase: {
    textTransform: "uppercase",
    color: "red",
  },
  success: {
    color: "black",
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    fontSize: "3rem",
  },
  link: {
    backgroundColor: "transparent",
    marginBottom: "1rem",
    /* @ts-ignore */
    color: ({ primaryColor }) => `${primaryColor}!important`,
    "&:hover": {
      textDecoration: "none",
      opacity: 0.8,
    },
  },
}));

interface Props {
  base_uri: string;
  dealerId: number;
  dealerType?: "single_dealer" | "multi_dealer" | "multi_region_dealer";
  pqType?: "no_pq" | "mfc" | "compuscan" | "interim_pq";
  applicationSubmission?: boolean;
  applicationUrl?: string;
  dealers?: Dealer[];
  activeDealerId?: number;
  masterDealerId?: number;
  purchasePrice?: number;
  mmCode?: string;
  variantId?: number;
  ownedModelId?: number;
  offerId?: number;
  colorId?: number;
  stockId?: number;
  leadTypeId?: number;
  financeQuestions?: any[];
  includeValidationChecks?: boolean;
  setPQResult?: Function;
  pqSuccessUrl?: string;
  pqFailUrl?: string;
  onSubmitForm?: Function;
  dealerModels?: any[];
  ownedVarientId?: number;
  floorCode?: string | undefined;
  sourceCode?: string | undefined;
  websiteColors?: any;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
  activeMake?: string | null;
  activeMakeId?: number;
  dealerMakesOnly?: boolean;
  overrideLeadType?: boolean;
}

interface Dealer {
  dealerId: any;
  name: any;
  relatedBrands: any;
}

interface Question {
  name: string;
  labelComponent: any;
  correct: boolean;
  order: number;
  answer?: boolean;
}

const Finance: FC<Props> = ({
  activeMake,
  activeMakeId,
  dealerMakesOnly,
  base_uri,
  dealerId,
  dealerType = "single_dealer",
  applicationSubmission = true,
  applicationUrl,
  dealers,
  activeDealerId,
  masterDealerId,
  pqType,
  purchasePrice,
  mmCode,
  variantId,
  ownedModelId,
  colorId,
  stockId,
  offerId,
  leadTypeId,
  financeQuestions,
  includeValidationChecks = false,
  setPQResult,
  pqSuccessUrl,
  pqFailUrl,
  onSubmitForm,
  dealerModels,
  ownedVarientId,
  floorCode,
  sourceCode,
  websiteColors,
  paper,
  paperColor,
  paperShadowColor,
  overrideLeadType = false,
}) => {

  const classes: any = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor });

  const defaultFinanceQuestions = [
    {
      name: "licenseCheck",
      labelComponent: (
        <span>I have a valid South African Driver's License</span>
      ),
      correct: true,
      order: 1,
    },
    {
      name: "creditCheck",
      labelComponent: <span>I consent to a credit bureau check</span>,
      correct: true,
      order: 2,
    },
    {
      name: "underDebtReview",
      labelComponent: <span>I confirm that I am not under debt review</span>,
      correct: true,
      order: 3,
    },
    {
      name: "needInsurance",
      labelComponent: <span>I will need insurance</span>,
      correct: true,
      order: 4,
    },
    {
      name: "popi",
      labelComponent: (
        <span>
          By submitting this form I agree to the{" "}
          <Link component={RouterLink} to="/terms" className={classes.link}>
            terms and conditions
          </Link>{" "}
          and{" "}
          <Link component={RouterLink} to="/privacy" className={classes.link}>
            privacy policies.
          </Link>
        </span>
      ),
      correct: true,
      order: 5,
    },
  ];

  const defaultValidationChecks = [
    {
      name: "checkA",
      labelComponent: <span>I am not a minor</span>,
      correct: true,
      order: 1,
    },
    {
      name: "checkB",
      labelComponent: (
        <span>I have never been declared mentally unfit by a court</span>
      ),
      correct: true,
      order: 2,
    },
    {
      name: "checkC",
      labelComponent: <span>I am not subject to an administration order</span>,
      correct: true,
      order: 3,
    },
    {
      name: "checkD",
      labelComponent: (
        <span>I do not have any current debt re-arrangement in existence</span>
      ),
      correct: true,
      order: 4,
    },
    {
      name: "checkE",
      labelComponent: (
        <span>I have not previously applied for a debt re-arrangement</span>
      ),
      correct: true,
      order: 5,
    },
    {
      name: "checkF",
      labelComponent: (
        <span>
          I do not have applications pending for credit, nor do I have any open
          quotations under section 92 of the National Credit Act 34 of 2005
          ("National Credit Act")
        </span>
      ),
      correct: true,
      order: 6,
    },
    {
      name: "checkG",
      labelComponent: <span>I am not currently in debt counselling</span>,
      correct: true,
      order: 7,
    },
    {
      name: "checkH",
      labelComponent: (
        <span>I consent to the Vehicle Finance Terms & Conditions</span>
      ),
      correct: true,
      order: 8,
    },
  ];

  if (!financeQuestions) {
    financeQuestions = defaultFinanceQuestions;

    if (includeValidationChecks) {
      financeQuestions.push(...defaultValidationChecks);
    }
  }

  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [success, setSuccess] = useState(false);
  const [delearName, setDelearName] = useState(undefined);
  const [delearDetails, setDelearDetails] = useState<any>(undefined);
  const [dealerList, setDealerList] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [modelId, setModelId] = useState(0);
  const [variants, setVariants] = useState([]);
  const [vehicleTypeId, setVehicleTypeId] = useState(leadTypeId || null);
  const [makes, setMakes] = useState<any>([]);
  const [models, setModels] = useState<any>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'grey',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
    },
  })(TextField);

  const getErrorMessage = (axiosError: any, defaultMsg: string) => {
    let errorTitle = defaultMsg;
    let errorDetails = "No additional details are available";

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = "Failed to send the request";
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.detail ?? errorDetails;
    }
    return `${errorTitle}: ${errorDetails}`;
  }

  const leadTypeOptions = [
    { id: 1, name: "New Vehicle" },
    { id: 2, name: "Used Vehicle" },
  ];

  const hasVehicle = variantId || stockId || ownedVarientId || offerId ? true : false;

  useEffect(() => {
    if (modelId) {
      setLoader(true);

      axios
        .get(`${base_uri}/dealervariants?dealerModelId=${modelId}`)
        .then((result) => {
          let data = result.data.list;
          if (data.length > 0) {
            setVariants(data);
          } else {
            return axios  // @ts-ignore
              .get(`${base_uri}/Variants`, { params: { modelId: modelId, isService: true } })
              .then((result) => {
                setVariants(result?.data?.list || []);
              })
              .catch((error) => {
                enqueueSnackbar(getErrorMessage(error, `Failed fetching variants: ${error}`), { variant: 'error' });
              })
              .then(() => {
                setLoadingOptions(false);
              });
          }
        })
        .catch((error) => {
          console.warn(`Failed fetching Variants for Model ID: ${modelId}`, error);
        })
        .then(() => {
          setLoader(false);
        });
    }
  }, [modelId]);

  let idRegex: any = `(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`;
  let phoneRegExp: any = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;
  var yupRules: any = {
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
  };

  if (!pqType || pqType != "no_pq") {
    yupRules = {
      ...yupRules,
      id: Yup.string()
        .required("ID number is required")
        .matches(idRegex, "ID Number invalid"),
    };
  }

  let validationSchema = Yup.object(yupRules);

  useEffect(() => {
    let f = dealers?.filter((d) => d.dealerId !== masterDealerId);
    // @ts-ignore
    setDelearName(f?.filter((d) => d.dealerId === activeDealerId)[0]?.name);

    if (dealerId !== masterDealerId) {
      setDelearDetails(dealers?.filter((d) => d.dealerId == dealerId)[0]);
    }

    if (dealers) {
      setDealerList(dealers)
    }
  }, [dealers, activeDealerId, dealerId]);

  let values = {
    id: "",
    netSalary: "",
    totalExpenses: "",
    name: "",
    lastName: "",
    phone: "",
    email: "",
    dealerId: delearDetails?.dealerId || activeDealerId || dealerId,
    dealer: delearDetails?.name || delearName || dealerId,
    leadTypeId: leadTypeId || "",
    make: activeMake ? activeMake : "",
    makeId: activeMakeId ? activeMakeId : "",
  };

  useEffect(() => {
    Promise.allSettled([
      getMakes(),
    ])
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, "Unexpected error occurred while loading form options"), { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModels = async (makeId: number) => {
    setLoadingOptions(true);
    if (makeId) {
      return await axios // @ts-ignore
        .get(`${base_uri}/Models/unfiltered`, {
          params: {
            makeId: makeId,
          }
        })
        .then((result) => {
          setModels(result?.data?.list || []);
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error, `Failed to load the models: ${error}`), { variant: 'error' });
        })
        .then(() => {
          setLoadingOptions(false);
        });
    }
  };

  const getDealers = async (makeId: number) => {
    if (makeId) {
      setLoadingOptions(true);
      // @ts-ignore
      const filteredDealers = dealers?.reduce((ac, v) => {
        // @ts-ignore
        const included = v.relatedBrands.reduce((acc, va) => {
          if (va.id === makeId) {
            return true;
          }
          return acc;
        }, false)
        if (included) {
          return [...ac, v];
        }
        return ac;
      }, []);

      setDealerList(filteredDealers);
    }
  };

  const getMakes = async () => {
    setLoadingOptions(true);

    return await axios
      .get(`${base_uri}/Makes`)
      .then((result: { data: { list: any[]; }; }) => {
        if (activeMakeId !== 0) {
          //@ts-ignore
          setMakes(result?.data?.list.filter((r: any) => r.id == activeMakeId) || []);
        } else if (dealerMakesOnly) {
          const brandIDs: any = dealers?.reduce((ac: number[], v) => {
            const aBrands = v.relatedBrands;
            const flatBrands = aBrands.reduce((acc: number[], val: object | any) => {
              if (ac.includes(val.id)) {
                return acc;
              }
              return [...acc, val.id];
            }, []);
            return [...ac, ...flatBrands];
          }, []);
          setMakes(result?.data?.list.filter((r: any) => brandIDs.includes(r.id)) || []);
        } else {
          setMakes(result?.data?.list || []);
        }
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, `Failed to load the makes: ${error}`), { variant: 'error' });
      })
      // always
      .then(() => {
        setLoadingOptions(false);
      });
  }

  const handleMakeChange = (e: any, value: any, setFieldValue: Function) => {
    handleSelectChange(e, value, setFieldValue, "make", "makeId");

    // Reset the Make & Variant selections
    setFieldValue("model", "");
    setFieldValue("modelId", 0);
    setFieldValue("variant", "");
    setFieldValue("variantId", 0);

    // Load the models for the selected make
    setTimeout(() => {
      getModels(value?.props?.id);
      if (dealerType == "multi_dealer") {
        getDealers(value?.props?.id);
      }
    }, 50);
  }

  useEffect(() => {
    if (activeMakeId && (!dealerModels || dealerModels?.length === 0)) {
      getModels(activeMakeId)
    };

    if (dealerModels && dealerModels?.length > 0) {
      setModels(dealerModels);
    }
  }, [activeMakeId, dealerModels])

  useEffect(() => {
    if (!activeMakeId && makes?.length === 1) {
      activeMakeId = makes[0]?.id;
      values.make = makes[0]?.name;
      values.makeId = makes[0]?.id;
      // Load the models for the selected make
      setTimeout(() => {
        getModels(makes[0]?.id);
        if (dealerType == "multi_dealer") {
          getDealers(makes[0]?.id);
        }
      }, 50);
    };
  }, [makes])

  const checkValid = (validForm: boolean, values: any) => {
    if (!validForm || !values) return false;
    let allChecked = true;

    if (financeQuestions) {
      for (var idx in financeQuestions) {
        var question = financeQuestions[idx];
        var questionVal = values[question.name] ?? false;

        if (question.correct != questionVal) {
          allChecked = false;
          break;
        }
      }
    }
    return validForm && allChecked;
  };

  const handleSelectChange = (e: any, value: any, setFieldValue: any, fieldName: any, fieldId: any) => {
    if (fieldName === "leadTypeId") {
      setFieldValue("dealer", "");
    };

    if (fieldName === "variantId") {
      const variant = variants?.find((v) => {
        //@ts-ignore
        return v?.id === e?.target?.value;
      })
      //@ts-ignore
      var tmpValue = variant?.variantId ? variant?.variantId : variant?.id;
      setFieldValue(fieldName, tmpValue);
    } else {
      setFieldValue(fieldName, e.target.value);
      setFieldValue(fieldId, value.props.id);
    }

    if (fieldName === "modelId") {
      setModelId(e.target.value);
    } else if (fieldName === "leadTypeId") {
      setVehicleTypeId(e.target.value);
    }
  };

  const createLead = (values: any, setSubmitting: Function) => {
    setSubmitting(true);

    const pageTitle = document.getElementsByTagName("title")[0].innerHTML;
    GAEvent('form_submissions', 'form_submissions', 'finance_submission', 'xhr', 
    {
      url: window.location.pathname,
      page_title: pageTitle,
      form_type: 'finance'
    });

    let params: any = {};
    params.leadStatusId = 1;
    var sourceId = 1; // Website

    if (!hasVehicle && !values?.leadTypeId) {
      enqueueSnackbar(`Vehicle type is required, please select a vehicle type`, { variant: "error" });
      return;
    }

    if (!hasVehicle && !values?.variantId && values?.leadTypeId === 1) {
      enqueueSnackbar(`Vehicle variant is required, please select a variant`, { variant: "error" });
      return;
    }

    if (document.referrer) {
      if (document.referrer.indexOf("facebook") > -1) {
        sourceId = 2; // Facebook
      }
    }

    setLoader(true);

    if (dealerType !== "single_dealer" && !values?.dealerId) {
      enqueueSnackbar(`A dealer is required, please select a dealer.`, { variant: "warning" });
      return;
    }

    params.dealerId = dealerType === "single_dealer" ? dealerId : values.dealerId;
    params.name = values.name + " " + values.lastName;
    params.firstName = values.name;
    params.lastName = values.lastName;
    params.phoneNumber = values.phone;
    params.emailAddress = values.email;
    params.leadSourceId = sourceId;
    params.leadTypeId = overrideLeadType ? values?.leadTypeId : 12; // Finance Application
    params.TypeCode = stockId ? "USED" : variantId ? "NEW" : "NoVehicle";
    params.dateUpdated = new Date().toISOString();
    //Lead Vehicle
    params.mmCode = mmCode ? mmCode : "";
    params.variantId = hasVehicle
      ? variantId ? variantId : 0
      : values?.variantId ? values.variantId : 0;

    if (!hasVehicle) {
      params.TypeCode = values.leadTypeId === 1 ? "NEW" : "USED";
    }

    params.ownedModelId = ownedModelId ? ownedModelId : 0;
    params.stockId = stockId ? stockId : 0;
    params.offerId = offerId ? offerId : 0;
    params.ownedVariantExteriorsId = colorId ? colorId : 0;

    if ((floorCode || "").length > 0 && (sourceCode || "").length > 0) {
      params.floorCode = floorCode;
      params.sourceCode = sourceCode;
    }
    // @ts-ignore
    axios.post(`${base_uri}/leads`, params)
      .then((response: any) => {
        values.leadId = response.data.id;
        if (pqType && pqType == "no_pq") {
          if (applicationSubmission && applicationUrl) {
            window.location.href = applicationUrl.replace(":leadId", values.leadId);
          }
          enqueueSnackbar(`Successfully submitted your information`, { variant: "success" });
          setSuccess(true);
          setLoader(false);
          return;
        }
        submitForPQ(values);
      })
      .catch((error: any) => {
        // TODO: REMOVE THIS SHIT
        enqueueSnackbar(`Successfully sent information`, { variant: 'success' });
        // enqueueSnackbar("Unable to complete the request", { variant: 'error' });
        setLoader(false);
      });
  }

  const submitForPQ = (values: any) => {
    let params: any = {
      idNumber: values.id,
      leadId: parseInt(values.leadId),
      netSalary: parseInt(values.netSalary),
      totalExpenses: parseInt(values.totalExpenses),
      desiredAmount: purchasePrice ? purchasePrice : 162900,
      underDebtReview: !values.underDebtReview,
      licenseCheck: values.licenseCheck,
      needInsurance: values.needInsurance
    };
    let url = `${base_uri}/applications/pq`;
    // @ts-ignore
    axios.post(url, params)
      .then((response: any) => {
        // @ts-ignore
        fbq("track", "Lead"); // eslint-disable-line
        setLoader(false);
        if (setPQResult != null) {
          setPQResult(response?.data);
        } else {
          window.location.href = !response?.data.includes("pqDecline")
            ? pqSuccessUrl?.replace(":leadId", values.leadId) || response.data
            : pqFailUrl?.replace(":leadId", values.leadId) || response.data;
        }
      })
      .catch((error: any) => {
        enqueueSnackbar(`Unable to complete request. ${error}`, {
          variant: "error",
        });
        setLoader(false);
      });
  }

  return (
    <React.Fragment>
      {/* @ts-ignore */}
      <MuiThemeProvider>
        <Grid container direction="row">
          <Grid item xs={12}>
            <div className={classes.holder}>
              <div className={classes.holderInner}>
                <Formik
                  initialValues={values}
                  isInitialValid={false}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={async (values: any, actions) => {
                    createLead(values, actions.setSubmitting);
                    if (onSubmitForm) {
                      onSubmitForm(values);
                    }
                  }}
                >
                  {(props: any) => {
                    const {
                      values,
                      touched,
                      errors,
                      isValid,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isSubmitting
                    } = props;
                    return (
                      <Form noValidate>
                        <Grid
                          container
                          spacing={2}
                          className={classes.root}
                          direction="column"
                        >
                          {/* Input fields */}
                          <Grid item xs={12} container spacing={2}>
                            {(!pqType || pqType !== "no_pq") && (
                              <Grid
                                item
                                className={classes.stepPaddingButton}
                                xs={12}
                                md={6}
                                lg={4}
                              >
                                <CssTextField
                                  id="id"
                                  name="id"
                                  label="Your SA ID"
                                  required
                                  value={values.id}
                                  fullWidth
                                  variant="outlined"
                                  helperText={touched.id ? errors.id : ""}
                                  error={touched.id && Boolean(errors.id)}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                />
                              </Grid>
                            )}

                            <Grid
                              item
                              className={classes.stepPaddingButton}
                              xs={12}
                              md={6}
                              lg={4}
                            >
                              <CssTextField
                                id="name"
                                name="name"
                                label="First Name"
                                required
                                value={values.name}
                                fullWidth
                                variant="outlined"
                                helperText={touched.name ? errors.name : ""}
                                error={touched.name && Boolean(errors.name)}
                                onChange={handleChange}
                                disabled={isSubmitting}
                              />
                            </Grid>

                            <Grid
                              item
                              className={classes.stepPaddingButton}
                              xs={12}
                              md={6}
                              lg={4}
                            >
                              <CssTextField
                                id="lastName"
                                name="lastName"
                                label="Last Name"
                                required
                                value={values.lastName}
                                fullWidth
                                variant="outlined"
                                helperText={
                                  touched.lastName ? errors.lastName : ""
                                }
                                error={
                                  touched.lastName && Boolean(errors.lastName)
                                }
                                onChange={handleChange}
                                disabled={isSubmitting}
                              />
                            </Grid>

                            <Grid
                              item
                              className={classes.stepPaddingButton}
                              xs={12}
                              md={6}
                              lg={4}
                            >
                              <CssTextField
                                id="email"
                                name="email"
                                type="email"
                                label="Email"
                                fullWidth
                                required
                                value={values.email}
                                variant="outlined"
                                helperText={touched.email ? errors.email : ""}
                                error={touched.email && Boolean(errors.email)}
                                onChange={handleChange}
                                disabled={isSubmitting}
                              />
                            </Grid>

                            <Grid
                              item
                              className={classes.stepPaddingButton}
                              xs={12}
                              md={6}
                              lg={4}
                            >
                              <CssTextField
                                id="phone"
                                name="phone"
                                type="tel"
                                label="Cellphone"
                                fullWidth
                                required
                                value={values.phone}
                                variant="outlined"
                                helperText={touched.phone ? errors.phone : ""}
                                error={touched.phone && Boolean(errors.phone)}
                                onChange={handleChange}
                                disabled={isSubmitting}
                              />
                            </Grid>

                            {(!pqType || pqType !== "no_pq") && (
                              <>
                                <Grid
                                  item
                                  className={classes.stepPaddingButton}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                >
                                  <CssTextField
                                    id="netSalary"
                                    name="netSalary"
                                    label="Your Nett Salary"
                                    value={values.netSalary}
                                    fullWidth
                                    variant="outlined"
                                    helperText={
                                      touched.netSalary ? errors.netSalary : ""
                                    }
                                    error={
                                      touched.netSalary &&
                                      Boolean(errors.netSalary)
                                    }
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  className={classes.stepPaddingButton}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                >
                                  <CssTextField
                                    id="totalExpenses"
                                    name="totalExpenses"
                                    label="Your Total Expenses"
                                    value={values.totalExpenses}
                                    fullWidth
                                    variant="outlined"
                                    helperText={
                                      touched.totalExpenses
                                        ? errors.totalExpenses
                                        : ""
                                    }
                                    error={
                                      touched.totalExpenses &&
                                      Boolean(errors.totalExpenses)
                                    }
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                  />
                                </Grid>
                              </>
                            )}
                            {!hasVehicle && (
                              <>
                                <Grid
                                  item
                                  className={classes.stepPaddingButton}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                >
                                  <CssTextField
                                    variant="outlined"
                                    fullWidth
                                    id="leadTypeId"
                                    select
                                    required
                                    disabled={leadTypeId == 1 || isSubmitting}
                                    label="Vehicle Type"
                                    value={values.leadTypeId}
                                    helperText={
                                      errors.leadTypeId && touched.leadTypeId
                                        ? errors.leadTypeId
                                        : ""
                                    }
                                    error={
                                      errors.leadTypeId && touched.leadTypeId
                                    }
                                    onBlur={handleBlur("leadTypeId")}
                                    //@ts-ignore
                                    onChange={(e, child) =>
                                      handleSelectChange(
                                        e,
                                        child,
                                        setFieldValue,
                                        "leadTypeId",
                                        "leadTypeId"
                                      )
                                    }
                                  >
                                    {leadTypeOptions.map((option) => (
                                      <MenuItem
                                        key={option.id}
                                        //@ts-ignore
                                        id={option.id}
                                        value={option.id}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </CssTextField>
                                </Grid>
                              </>
                            )}

                            {!hasVehicle && vehicleTypeId === 1 && (
                              <>
                                {
                                  ((!dealerModels || dealerModels?.length < 1) || (dealerType === "multi_dealer" || dealerType === "multi_region_dealer")) &&
                                  (<Grid
                                    item
                                    className={classes.stepPaddingButton}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                  >
                                    <CssTextField
                                      select
                                      fullWidth
                                      required
                                      id="make"
                                      label="Make"
                                      variant="outlined"
                                      value={makes.length === 1 ? makes[0].name : values.make}
                                      disabled={isSubmitting || (!values?.makeId && loadingOptions)
                                        || makes.length === 1
                                        //  || activeMake !== null || activeMakeId !== null 
                                      }
                                      helperText={errors.make && touched.make ? errors.make : ""}
                                      error={touched.make && Boolean(errors.make)}
                                      onBlur={handleBlur("make")}
                                      //@ts-ignore
                                      onChange={(e: any, child: any) => {
                                        handleMakeChange(e, child, setFieldValue);
                                      }}
                                    >
                                      {makes.map((option: any) => (
                                        <MenuItem key={option.id} id={option.id} value={option.name}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </CssTextField>
                                  </Grid>)
                                }

                                <Grid
                                  item
                                  className={classes.stepPaddingButton}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                >
                                  <CssTextField
                                    variant="outlined"
                                    fullWidth
                                    id="modelId"
                                    select
                                    required
                                    label="Model"
                                    value={values.modelId}
                                    disabled={isSubmitting || (!values?.modelId && loadingOptions) || (!values?.make && (!activeMakeId || activeMakeId === 0))}
                                    helperText={
                                      errors.modelId && touched.modelId
                                        ? errors.modelId
                                        : ""
                                    }
                                    error={errors.modelId && touched.modelId}
                                    onBlur={handleBlur("modelId")}
                                    //@ts-ignore
                                    onChange={(e, child) =>
                                      handleSelectChange(
                                        e,
                                        child,
                                        setFieldValue,
                                        "modelId",
                                        "modelId",
                                      )
                                    }
                                  >
                                    {
                                      // @ts-ignore
                                      models?.map((option) =>
                                      (<MenuItem
                                        key={option.id}
                                        id={option.id}
                                        value={option.id}
                                      >
                                        {option.model}
                                      </MenuItem>)
                                      )
                                    }
                                  </CssTextField>
                                </Grid>

                                <Grid
                                  item
                                  className={classes.stepPaddingButton}
                                  xs={12}
                                  md={6}
                                  lg={4}
                                >
                                  <CssTextField
                                    variant="outlined"
                                    fullWidth
                                    id="variantId"
                                    select
                                    required
                                    label="Variant"
                                    value={values.variantId}
                                    disabled={isSubmitting || !values.modelId || variants?.length === 0}
                                    helperText={
                                      errors.variantId && touched.variantId
                                        ? errors.variantId
                                        : ""
                                    }
                                    error={
                                      errors.variantId && touched.variantId
                                    }
                                    onBlur={handleBlur("variantId")}
                                    // @ts-ignore
                                    onChange={(e: any, child: any) =>
                                      handleSelectChange(
                                        e,
                                        child,
                                        setFieldValue,
                                        "variantId",
                                        "variantId"
                                      )
                                    }
                                  >
                                    {variants.map((option) => (
                                      //@ts-ignore
                                      <MenuItem key={option?.id} id={option?.id} value={option?.id}
                                      >
                                        {/*//@ts-ignore*/}
                                        {option?.variantName}
                                      </MenuItem>
                                    ))}
                                  </CssTextField>
                                </Grid>
                              </>
                            )}

                            {dealerType == "multi_dealer" && (
                              <Grid
                                item
                                className={classes.stepPaddingButton}
                                xs={12}
                                md={6}
                                lg={4}
                              >
                                <CssTextField
                                  variant="outlined"
                                  fullWidth
                                  id="dealer"
                                  disabled={isSubmitting || ((!values?.make && (!activeMakeId || activeMakeId === 0)) && (leadTypeId === 1 || values?.leadTypeId === 1))}
                                  select={
                                    delearName !== undefined
                                      ? false
                                      : true && delearDetails !== undefined
                                        ? false
                                        : true
                                  }
                                  label="Dealership"
                                  value={values.dealer}
                                  helperText={
                                    errors.dealer && touched.dealer
                                      ? errors.dealer
                                      : ""
                                  }
                                  error={errors.dealer && touched.dealer}
                                  onBlur={handleBlur("dealer")}
                                  /* @ts-ignore */
                                  onChange={(e: any, child: any) =>
                                    handleSelectChange(
                                      e,
                                      child,
                                      setFieldValue,
                                      "dealer",
                                      "dealerId"
                                    )
                                  }
                                >
                                  {/* @ts-ignore */}
                                  {dealerList?.map((option) => (
                                    <MenuItem
                                      key={option.dealerId}
                                      id={option.dealerId}
                                      value={option.name}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </CssTextField>
                              </Grid>
                            )}
                          </Grid>

                          {/* Checkboxes */}
                          {financeQuestions && (
                            <Grid item xs={12} container spacing={1}>
                              {financeQuestions.map((itm: Question) => (
                                <Grid
                                  item
                                  className={classes.flex}
                                  xs={12}
                                  md={6}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={itm.name}
                                        color="primary"
                                        onChange={handleChange}
                                      />
                                    }
                                    label={itm.labelComponent}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          )}

                          {/* Form action buttons */}
                          <Grid item xs={12} container spacing={2}>
                            <Grid
                              item
                              className={classes.stepPaddingButton}
                              xs={12}
                            >
                              <Button
                                className={classes.button}
                                variant={"contained"}
                                color="primary"
                                type="submit"
                                disabled={
                                  !checkValid(isValid, values) ||
                                  loader === true
                                }
                              >
                                {/*@ts-ignore*/}
                                {loader === true ? (<CircularProgress />
                                ) : (
                                  "Apply now"
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Grid>
        </Grid>
        {pqType && pqType == "mfc" && (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", padding: "10px 25px" }}
            >
              <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                You will be redirected to MFC for a brief moment to authenticate
                your session, don't worry you will come right back here. This is
                a necessary step to protect your information.
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", padding: "10px 25px" }}
            >
              <span style={{ fontSize: "0.8rem", textAlign: "center" }}>
                All calculations made on calculators supplied on this site,
                together with rates quoted, are guidelines only and are subject
                to confirmation at the time of finalising any transactions. All
                information regarding the products, fees and/or costs that are
                included in and form a fundamental basis of the calculations are
                subject to change at any time prior to a final pre-agreement
                quote being handed to the User. The User indemnifies Askon
                InspectaCar against any loss or liability, which the User may
                suffer as a result of the use of any calculator. The site and
                all information provided on this site and the services provided
                on this site, are provided "as is". The information provided on
                this site should not be treated as professional advice of any
                kind.
              </span>
            </Grid>
          </Grid>
        )}
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default Finance;
