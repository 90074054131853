import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { 
    Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    Collapse,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    Typography 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { 
    INode,
    IRange,
    IRangeCfg,
    IStock 
} from "../Interfaces";
import { RangeSlider } from "../VehiclesFilterV2/RangeSlider";
import { ArrowDropDown, ExpandLess, ExpandMore } from "@material-ui/icons";

const sumColorComponents = (colorHexString: string) => {

    let result = 0;

    try {
        
        const hexString: string = colorHexString.replace("#", "");
        let redHex: string = "";
        let greenHex: string = "";
        let blueHex: string = "";
    
        switch (hexString.length) {
            case 8:
            case 6:
                // Long version "#rrggbbaa" or "#rrggbb" 
                redHex = hexString.substring(0, 2);
                greenHex = hexString.substring(2, 4);
                blueHex = hexString.substring(4, 6);
                break;
            case 4:
            case 3:
                // Short version "#rgba" or "#rgb"
                redHex = `${hexString.substring(0, 1)}${hexString.substring(0, 1)}`;
                greenHex = `${hexString.substring(1, 2)}${hexString.substring(1, 2)}`;
                blueHex = `${hexString.substring(2, 3)}${hexString.substring(2, 3)}`;
                break;
            default:
                // Invalid hex string passed, return default
                return result;
        }
    
        let r = parseInt(redHex, 16) * 0.2126;
        let g = parseInt(greenHex, 16) * 0.7152;
        let b = parseInt(blueHex, 16) * 0.0722;

        return r + g + b;

    } catch {
        
        return result;

    }

};

const useStyles = makeStyles((theme) => ({
    /***  Credit for converting color: https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/   ***/
    searchToggleContainer: {
        width: "100%",
        //@ts-ignore
        maxHeight: ({ isOpen }) => isOpen ? "0" : "200px",
        //@ts-ignore
        visibility: ({ isOpen }) => isOpen ? "hidden" : "visible"
    },
    searchToggleButton: {
        width: "100%"
    },
    closeIconContainer: {
        padding: "5px",
        position: "absolute",
        right: 5,
        top: 0
    },
    closeIcon: {
        padding: "5px",
        fontSize: "25px",
        fontWeight: 600,
        cursor: "pointer",
         //@ts-ignore
        color: ({ secondaryColor }) => `${secondaryColor? secondaryColor : `hsl(0, 0%, calc((calc((${sumColorComponents(theme.palette.secondary.main)}) / 255)) * -10000000%))`} !important`,
        "&:hover": {
              //@ts-ignore
            color:({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        }
    },
    outerContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
         //@ts-ignore
        color: ({ secondaryColor }) => `${secondaryColor? secondaryColor : `hsl(0, 0%, calc((calc((${sumColorComponents(theme.palette.secondary.main)}) / 255)) * -10000000%))`}`,
        //@ts-ignore
        background: ({ applyThemeBackground, secondaryColor }) => applyThemeBackground === true ? secondaryColor ? secondaryColor : theme.palette.secondary.main : "white",
        //@ts-ignore
        maxHeight: ({ isOpen }) => isOpen ? "1000px" : "0",
        //@ts-ignore
        padding: ({ isOpen }) => isOpen ? "30px 20px" : "0",
        //@ts-ignore
        visibility: ({ isOpen }) => isOpen ? "visible" : "hidden",
        transition: "max-height 0.5s ease-in"
    },
    header: {
        fontSize: "20px",
        fontWeight: 600,
        textAlign: "center",
        textTransform: "capitalize",
        marginBottom: "20px"
    },
    standardTextBold: {
        fontSize: "13px",
        fontWeight: 600
    },
    searchField: {
        width: "100%",
        marginBottom: "15px",
        "& input": {
            background: "white"
        },
        "& $MuiInputBase-root": {
            background: "white"
        }
    },
    searchFieldGrouped: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textTransform: "capitalize",
        "& $searchField": {
            width: "48% !important"
        }
    },
    searchFieldOutlineContainer: {
        padding: "5px",
        height: "fit-Content",
        background: "white",
        fontSize: "14px",
        //@ts-ignore
        color: ({ secondaryColor }) =>`${secondaryColor? secondaryColor : `hsl(0, 0%, calc((calc((${sumColorComponents(theme.palette.secondary.main)}) / 255 - 0.5)) * -100000))`}`,
        border: '1px solid #cecece !important',
        borderRadius: '5px !important',
        "&& svg ":{
            //@ts-ignore
            color: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        },
        "&:hover": {
            border: `1px solid black !important`,
            borderRadius: '5px !important',
        }
    },
    searchBarContainer: {
        height: "fit-content",
        display: "flex",
        justifyContent: "start",
        flexWrap: "wrap",
        listStyle: "none",
        margin: 0,
        boxShadow: "unset",
    },
    searchInput: {
        flexGrow: 1,
        minWidth: "100px",
        padding: "0 10px",
        border: "none",
        "&:focus": {
            outline: "none",
        },
    },
    selectedMakeChipContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "flex-start",
        overflow: "hidden"
    },
    selectedMakeChip: {
        margin: "10px"
    },
    dropdownCaretContainer: {
        padding: "5px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
    },
    dropdownCaret: {
        transition: "transform 0.5s ease-in !important"
    },
    searchResultsText: {
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center",
        textTransform: "capitalize",
        margin: "10px 0"
    },
    searchVehiclesTree: {
        width: "100%",
        height: "fit-Content",
        maxHeight: "30vh",
        overflowY: "auto",
    },
    searchResultsVehiclesContainer: {
        width: "100%"
    },
    nodeCheckbox: {
        "&& svg ":{
              //@ts-ignore
            color: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        }
    },
    childNode: {
        paddingLeft: "45px"
    },
    buttensContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    resetButton: {
        padding: "10px 15px",
        marginRight: "5px"
    },
    resultsButton: {
        padding: "10px 15px",
        flexGrow: 1
    },
    primaryButton: {
        border: "none",
        //@ts-ignore
        color: ({ primaryColor }) => `hsl(0, 0%, calc((calc((${sumColorComponents(primaryColor ? primaryColor : theme.palette.primary.main)}) / 255) - 0.55) * -10000000%))`,
         //@ts-ignore
        backgroundColor: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        // background: theme.palette.primary.main,
        "&:hover": {
            //@ts-ignore
            color: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
             //@ts-ignore
            background: ({ secondaryColor }) => `${secondaryColor ? secondaryColor : theme.palette.secondary.main} !important`,
             //@ts-ignore
            border:  ({ primaryColor }) => `1px solid ${primaryColor ? primaryColor : theme.palette.primary.main}`
        }
    },
    transparentButton: {
        background: "transparent",
        //@ts-ignore
        color: ({ secondaryColor }) => `${secondaryColor ? secondaryColor :  `hsl(0, 0%, calc((calc((${sumColorComponents(theme.palette.secondary.main)}) / 255) - 0.55) * -10000000%))`} !important`,
        borderWidth: '1px',
        borderStyle: 'solid',
        //@ts-ignore
        borderColor: ({ secondaryColor }) => `${secondaryColor ? secondaryColor : `hsl(0, 0%, calc((calc((${sumColorComponents(theme.palette.secondary.main)}) / 255) - 0.55) * -10000000%))`}`,
        "&:hover": {
            //@ts-ignore
            color: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
          //@ts-ignore
            borderColor: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        }
    },
    customDropDown: {
        '& .MuiSelect-icon': {
         //@ts-ignore
         color: ({ primaryColor }) => `${primaryColor ? primaryColor : theme.palette.primary.main} !important`,
        },
      },
}));

const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
            textTransform: "capitalize"
        },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
};

function toTitleCase(str: any) {
    return str?.replace(/\w\S*/g, function (txt: any) {
        return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function removeDuplicates(data: any[], key: any) {
    return [
        ...new Map(
            data.map(x => [key(x), x])
        ).values()
    ];
}

function getInstallmentEstimate(price: number): number {
    return parseInt(Math.round(price / 60).toFixed(0));
}

function isValidId(id: number | string | undefined | null) {

    if (!id) return false;

    return Number(id) > 0;

}

const mileageFormatter = new Intl.NumberFormat('af', {
    style: 'unit',
    //@ts-ignore
    unit: "kilometer",
    minimumFractionDigits: 0
});

interface IProps {
    headerLabel?: string | undefined | null;
    applyThemeBackground?: boolean | undefined | null;
    vehicles: IStock[];
    updateFilteredVehicles: Function;
    websiteColors?: any;
};

const VehiclesFilterV3: FC<IProps> = (props) => {

    const {
        headerLabel = "Find Used Cars For Sale",
        applyThemeBackground,
        vehicles,
        updateFilteredVehicles,
        websiteColors,
    } = props;

    const instalmentRangeInterval = 1500;
    const priceRangeInterval = 50000;
    const yearRangeInterval = 1;

    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showVehiclesTree, setShowVehiclesTree] = useState(false);

    const [allVehicleNodes, setAllVehicleNodes] = useState<INode[]>([]);
    const [searchedVehicleNodes, setSearchedVehicleNodes] = useState<INode[]>([]);

    const [priceRanges, setPriceRanges] = useState<IRangeCfg>();
    const [yearRanges, setYearRanges] = useState<IRangeCfg>();
    const [instalmentRanges, setInstalmentRanges] = useState<IRangeCfg>();
    const [transmissions, setTransmissions] = useState<(string | undefined)[]>([]);
    const [bodyTypes, setBodyTypes] = useState<(string | undefined)[]>([]);
    const [mileageRanges, setMileageRanges] = useState<(IRange | undefined)[]>([]);
    const [fuelTypes, setFuelTypes] = useState<(string | undefined)[]>([]);
    
    const [selectedVehicleNodes, setSelectedVehicleNodes] = useState<INode[]>([]);
    const [selectedPriceRange, setSelectedPriceRange] = useState([0, 0]);
    const [selectedYearRange, setSelectedYearRange] = useState([0, 0]);
    const [selectedInstalmentRange, setSelectedInstalmentRange] = useState([0, 0]);
    const [selectedTransmission, setSelectedTransmission] = useState("");
    const [selectedBodyType, setSelectedBodyType] = useState("");
    const [selectedMileageRange, setSelectedMileageRange] = useState<IRange | string>("");
    const [selectedFuelType, setSelectedFuelType] = useState("");
    const [makeModelSearchText, setMakeModelSearchText] = useState("");
    const [keywordSearchText, setKeywordSearchText] = useState("");
    const [stockNoSearchText, setStockNoSearchText] = useState("");

    const classes = useStyles({ ...websiteColors, isOpen, applyThemeBackground });
    const useCustomStyles = makeStyles(() => ({
        root: {
            // border: `1px solid #cecece !important`,
            // borderWidth: "1px !important",
          
            "& $notchedOutline": {
                borderColor: `#cecece !important`,
                borderRadius: '5px !important',
            },
            "&:hover fieldset": {
                borderColor: `#000 !important`,
                borderRadius: '5px !important',
            },
            "&$focused $notchedOutline": {
                borderColor: `${websiteColors ? websiteColors.primaryColor : 'black'} !important`,
                borderRadius: '5px !important',
            },
        },
        focused: {},
        notchedOutline: {}
    }))
    const customClasses = useCustomStyles()

    useEffect(() => {
        
        if (!(vehicles?.length > 0)) return;

        getVehicleNodes();
        getPriceRangeOptions();
        getYearRangeOptions();
        getInstalmentRangeOptions();
        getTransmissionOptions();
        getBodyTypeOptions();
        getMileageRangeOptions();
        getFuelTypeOptions();

        updateFilteredVehicles([...vehicles]);
        
    }, [vehicles]);

    useEffect(() => {

        if (!(allVehicleNodes?.length > 0)) return;

        const searchResults = allVehicleNodes.filter((item) => 
            item?.id?.toLowerCase()?.includes(makeModelSearchText?.toLowerCase()) ||
            item?.children?.findIndex((itm) =>
                itm?.name?.toLowerCase()?.includes(makeModelSearchText?.toLowerCase()) ||
                itm?.stockNos?.some(s => s?.toLowerCase().includes(makeModelSearchText?.toLowerCase()))
            ) !== -1
        );

        setSearchedVehicleNodes(searchResults);

    }, [makeModelSearchText]);

    const getVehicleNodes = () => {

        let nodes = [] as INode[];

        const filteredMakes = Array
            .from(new Set(vehicles.map((v) => toTitleCase(v?.brand))))
            .sort();

        filteredMakes.forEach((make) => {

            const makeModels = Array
                .from(new Set(
                    vehicles
                        .filter((v) => v?.brand?.toUpperCase() === make?.toUpperCase())
                        .map((v) => { return { modelName: v?.model, stockNo: v?.stockNo } })
                ))
                .sort();

            let tmpModelsChildren = [] as INode[];
            let uniqueModels = [] as string[];

            makeModels.forEach((model: any) => {

                let modelParts = model?.modelName?.split(" ");
                let modelShortName = modelParts?.length > 0 ? modelParts[0] : null;

                if (modelShortName) {
                    if (uniqueModels?.indexOf(modelShortName) === -1) {

                        uniqueModels?.push(modelShortName);

                        let childModel: INode = {
                            id: model?.modelName,
                            name: model?.modelName,
                            stockNos: [model?.stockNo],
                            displayName: `${make} ${model?.modelName}`,
                            isSelected: false,
                            expanded: false,
                            isChildSelected: false,
                            parentId: make,
                            children: []
                        };

                        tmpModelsChildren.push(childModel);

                    } else {
                        
                        let tempModelsChild = tmpModelsChildren.find(c => c.name.startsWith(modelShortName));

                        tempModelsChild?.stockNos.push(model?.stockNo);

                    }
                }

            });

            let tmpMake: INode = {
                id: make,
                name: make,
                stockNos: [],
                displayName: make,
                isSelected: false,
                expanded: false,
                parentId: null,
                isChildSelected: false,
                children: tmpModelsChildren,
            };

            nodes.push(tmpMake);

        });

        setAllVehicleNodes(nodes);
        setSearchedVehicleNodes(nodes);

    };

    const getPriceRangeOptions = () => {
        
        const priceStart = Math.floor(
            Math.min(...vehicles.map((o) => o.price)) / priceRangeInterval
        ) * priceRangeInterval;
        const priceEnd = Math.ceil(
            Math.max(...vehicles.map((o) => o.price)) / priceRangeInterval
        ) * priceRangeInterval;
        const price = {
            interval: priceRangeInterval,
            start: isFinite(priceStart) ? priceStart : 0,
            end: isFinite(priceEnd) ? priceEnd : 0,
        };

        setPriceRanges(price);
        setSelectedPriceRange([price.start, price.end]);

    };
    
    const getYearRangeOptions = () => {
        
        const yearStart = Math.floor(
            Math.min(...vehicles.filter(v => v.year > 0).map((o) => o.year)) / yearRangeInterval
        ) * yearRangeInterval;
        const yearEnd = Math.ceil(
            Math.max(...vehicles.map((o) => o.year)) / yearRangeInterval
        ) * yearRangeInterval;
        const year = {
            interval: yearRangeInterval,
            start: isFinite(yearStart) ? yearStart : 0,
            end: isFinite(yearEnd) ? yearEnd : 0,
        };

        setYearRanges(year);
        setSelectedYearRange([year.start, year.end]);

    };

    const getInstalmentRangeOptions = () => {

        const instalStart = Math.floor(
            Math.min(...vehicles.map((o) => getInstallmentEstimate(o.price))) /
            instalmentRangeInterval
        ) * instalmentRangeInterval;
        
        const instalEnd = Math.ceil(
            Math.max(...vehicles.map((o) => getInstallmentEstimate(o.price))) /
            instalmentRangeInterval
        ) * instalmentRangeInterval;

        const instalment = {
            interval: instalmentRangeInterval,
            start: isFinite(instalStart) ? instalStart : 0,
            end: isFinite(instalEnd) ? instalEnd : 0,
        };

        setInstalmentRanges(instalment);
        setSelectedInstalmentRange([instalment.start, instalment.end]);

    };

    const getTransmissionOptions = () => {

        const allOptions = vehicles.map(v => v.transmission?.trim()?.toLowerCase()).filter(o => o && o.length > 0);
        const sortedOptions = removeDuplicates(allOptions, (o: string) => o).sort((a, b) => a > b ? 1 : -1);

        setTransmissions(sortedOptions || []);

    };

    const getBodyTypeOptions = () => {

        const allOptions = vehicles.map(v => v.bodyType?.trim()?.toLowerCase()).filter(o => o && o.length > 0);
        const sortedOptions = removeDuplicates(allOptions, (o: string) => o).sort((a, b) => a > b ? 1 : -1);

        setBodyTypes(sortedOptions || []);

    };

    const getMileageRangeOptions = () => {

        const allRanges = [
            { min: 0, max: 10000 },
            { min: 10000, max: 20000 },
            { min: 20000, max: 30000 },
            { min: 30000, max: 40000 },
            { min: 40000, max: 50000 },
            { min: 50000, max: 60000 },
            { min: 60000, max: 70000 },
            { min: 70000, max: 80000 },
            { min: 80000, max: 90000 },
            { min: 100000, max: 120000 },
            { min: 120000, max: 140000 },
            { min: 140000, max: 160000 },
            { min: 160000, max: 180000 },
            { min: 180000, max: 200000 },
            { min: 200000, max: 250000 },
            { min: 250000, max: 300000 },
            { min: 300000, max: 1000000 }
        ];
        
        const mileages = vehicles.map((v) => v.mileage ? Number(v.mileage) : 0);
        const filteredRanges = allRanges.filter(r => mileages.some(p => p >= r.min && p < r.max));

        setMileageRanges(filteredRanges || []);

    };

    const getFuelTypeOptions = () => {

        const allOptions = vehicles.map(v => v.fuelType?.trim()?.toLowerCase()).filter(o => o && o.length > 0);
        const sortedOptions = removeDuplicates(allOptions, (o: string) => o).sort((a, b) => a > b ? 1 : -1);

        setFuelTypes(sortedOptions || []);

    };

    const selectPriceRange = (val: number[]) => { setSelectedPriceRange(val) };
    const selectYearRange = (val: number[]) => { setSelectedYearRange(val) };
    const selectInstalmentRange = (val: number[]) => { setSelectedInstalmentRange(val) };
    const selectTransmission = (e: any) => { setSelectedTransmission(e?.target?.value) };
    const selectBodyType = (e: any) => { setSelectedBodyType(e?.target?.value) };
    const selectMileageRange = (e: any) => { setSelectedMileageRange(e?.target?.value) };
    const selectFuelType = (e: any) => { setSelectedFuelType(e?.target?.value) };
    const onChangeMakeModelSearchText = (e: any) => { setMakeModelSearchText(e?.target?.value) };
    const onChangeKeywordSearchText = (e: any) => { setKeywordSearchText(e?.target?.value) };
    const onChangeStockNoSearchText = (e: any) => { setStockNoSearchText(e?.target?.value) };

    const toggleSearcOptions = () => {

        setIsOpen(!isOpen);

    };

    const toggleVehiclesTree = () => {
        
        setShowVehiclesTree(!showVehiclesTree);

    };

    const handleNodeSelected = (item: INode, e: any) => {

        e.stopPropagation();

        const selectedListCopy = [...selectedVehicleNodes];
        const index = selectedListCopy.indexOf(item);

        let parentHasSelectedChild = false;

        if (index === -1) {

            selectedListCopy.push(item);

            // Check is selected is parent then remove all chindren
            if (isValidId(item?.parentId)) {

                const parentIndex = selectedListCopy.findIndex((v) => v?.id === item?.parentId);

                if (parentIndex !== -1) {
                    selectedListCopy?.splice(parentIndex, 1);
                }

            }

        } else {

            selectedListCopy?.splice(index, 1);

        }

        // Check if item is child
        if (isValidId(item?.parentId)) {

            // Check if item's parent still has children in selected list
            const index = selectedListCopy.findIndex((itm) => itm.parentId === item.parentId);
            
            parentHasSelectedChild = index >= 0 ? true : false;

        } else {

            // If parent is selected then clear all child items
            if (item?.children && item.children.length > 0) {

                const parentChildren = [...item.children];

                parentChildren.forEach((parentChild) => {
                    const parentChildIndex = selectedListCopy.indexOf(parentChild);

                    if (parentChildIndex !== -1) {
                        selectedListCopy.splice(parentChildIndex, 1);
                    }
                });

            }

        }

        setSelectedVehicleNodes(selectedListCopy);

        const vehicleListCopy = [...allVehicleNodes];

        vehicleListCopy.forEach((listItem) => {

            if (listItem?.id === item?.id) {

                listItem.isSelected = !item?.isSelected;

                if (!isValidId(item?.parentId) && item?.isChildSelected) {

                    listItem.isSelected = true;
                    listItem.isChildSelected = false;

                    if (listItem?.children && listItem.children.length > 0) {
                        const parentChildren = [...listItem.children];

                        parentChildren.forEach((child) => child.isSelected = false);
                    }

                }
            }

            // Update parent if item is child, current ListItem is not parent and current listItem is child's parent and parent does not have child in selectedList
            if (isValidId(item?.parentId) && !isValidId(listItem?.parentId) && listItem.id === item?.parentId) {

                if (parentHasSelectedChild) {
                    listItem.isSelected = false;
                }

                listItem.isChildSelected = parentHasSelectedChild ? true : false;

            }

            // Ensure child is updated
            if (listItem?.children && listItem.children.length > 0) {

                const childList = [...listItem?.children];

                childList?.forEach((child) => {
                    if (child?.id === item?.id) {
                        child.isSelected = !item?.isSelected;
                    }
                });

            }
        });

        setAllVehicleNodes(vehicleListCopy);

    };

    const handleNodeExpand = (item: INode, e: any) => {

        e.stopPropagation();

        const vehicleListCopy = [...allVehicleNodes];

        vehicleListCopy.forEach((listItem) => {
            if (listItem?.id === item?.id) {
                listItem.expanded = !item?.expanded;
            }
        });

        setAllVehicleNodes(vehicleListCopy);

    };

    const renderVehicleNode = (item: INode) => {
        return (
            <>
                <ListItem key={item?.id} role={undefined} dense button>
                    <ListItemIcon onClick={(e) => handleNodeSelected(item, e)}>
                        <Checkbox
                            indeterminate={item?.isChildSelected ? true : false}
                            edge="start"
                            className={classes.nodeCheckbox}
                            checked={selectedVehicleNodes.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": item?.id }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        id={item?.id}
                        primary={`${item?.name}`}
                        onClick={(e) => handleNodeSelected(item, e)}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={(e) => handleNodeExpand(item, e)}
                        >
                            {item?.expanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={item?.expanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            item?.children?.map((child: INode) => (
                                <ListItem
                                    button
                                    className={classes.childNode}
                                    onClick={(e) => handleNodeSelected(child, e)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedVehicleNodes.indexOf(child) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": child?.id }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={child?.name} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Collapse>
            </>
        );
    };

    function reset() {
        
        let resetNodes = [...allVehicleNodes];

        resetNodes.forEach(node => {
            node.isSelected = false;
            node.isChildSelected = false;
        });

        setAllVehicleNodes(resetNodes);
        setSearchedVehicleNodes(resetNodes);
        setSelectedVehicleNodes([]);
        setSelectedPriceRange([0, 0]);
        setSelectedYearRange([0, 0]);
        setSelectedInstalmentRange([0, 0]);
        setSelectedTransmission("");
        setSelectedBodyType("");
        setSelectedMileageRange("");
        setSelectedFuelType("");
        setMakeModelSearchText("");
        setKeywordSearchText("");
        setStockNoSearchText("");

        setIsFilterApplied(false);
        updateFilteredVehicles([...vehicles]);

    }

    function filter() {

        let filteredVehicles = [...vehicles];
        
        if (selectedVehicleNodes && selectedVehicleNodes.length > 0) {

            const selectedNodeKeys = Array.from(new Set(selectedVehicleNodes.map(node => node.id.toUpperCase())));

            filteredVehicles = filteredVehicles.filter(v => {
                
                const modelParts = v?.model?.split(" ");
                const modelShortName = modelParts?.length > 0 ? modelParts[0] : null;

                return (
                    (v?.brand?.length > 0 && selectedNodeKeys.includes(v.brand.toUpperCase())) ||
                    (
                        modelShortName && modelShortName.length > 0 &&
                        (selectedNodeKeys.includes(modelShortName.toUpperCase()) || selectedNodeKeys.includes(v.model.toUpperCase()))
                    )
                );
            });
        }

        filteredVehicles = filteredVehicles
            .filter((o) =>
                o.price &&
                o.price >= selectedPriceRange[0] &&
                o.price <= selectedPriceRange[1]
            )
            .filter((o) =>
                o.price &&
                getInstallmentEstimate(o.price) >= selectedInstalmentRange[0] &&
                getInstallmentEstimate(o.price) <= selectedInstalmentRange[1]
            )
            .filter((o) =>
                o.year &&
                o.year >= selectedYearRange[0] &&
                o.year <= selectedYearRange[1]
            )
            .filter((o) =>
                selectedTransmission.length > 0
                    ? o.transmission && selectedTransmission === o.transmission.trim().toLowerCase()
                    : true
            )
            .filter((o) =>
                selectedFuelType.length > 0
                    ? o.fuelType && selectedFuelType === o.fuelType.trim().toLowerCase()
                    : true
            )
            .filter((o) =>
                selectedBodyType.length > 0
                    ? o.bodyType && selectedBodyType === o.bodyType.trim().toLowerCase()
                    : true
            )
            .filter((o) =>
                selectedMileageRange && Object.keys(selectedMileageRange).length > 0
                    //@ts-ignore
                    ? o.mileage && Number(o.mileage) >= selectedMileageRange.min && Number(o.mileage) <= selectedMileageRange.max
                    : true
            )
            .filter((o) =>
                keywordSearchText.length > 0
                    ?   (
                            o.brand?.toLowerCase().includes(keywordSearchText.trim().toLowerCase()) || 
                            o.model?.toLowerCase().includes(keywordSearchText.trim().toLowerCase()) || 
                            o.modelRange?.toLowerCase().includes(keywordSearchText.trim().toLowerCase())
                        )
                    :   true
            )
            .filter((o) =>
                stockNoSearchText.length > 0
                    ? o.stockNo && o.stockNo.toLowerCase().includes(stockNoSearchText.trim().toLowerCase())
                    : true
            );

        setIsFilterApplied(true);
        updateFilteredVehicles([...filteredVehicles]);
        
    }

    return (
        <React.Fragment>
            <div className={classes.searchToggleContainer}>
                <Button className={`${classes.primaryButton} ${classes.searchToggleButton}`} onClick={toggleSearcOptions}>
                    Click here to search vehicles
                </Button>
            </div>

            <div className={classes.outerContainer}>

                <div className={classes.closeIconContainer} onClick={toggleSearcOptions}>
                    <CloseIcon className={classes.closeIcon} />
                </div>
                
                {
                    (headerLabel || "")?.length > 0 &&
                    <Typography className={classes.header}>
                        {headerLabel}
                    </Typography>
                }

                <div className={classes.searchField}>
                    <Typography className={classes.standardTextBold} gutterBottom align="left">
                        Search by:
                    </Typography>
                </div>
                
                <div className={classes.searchField}>
                    <ClickAwayListener onClickAway={() => setShowVehiclesTree(false)}>
                        <div className={classes.searchFieldOutlineContainer}>
                            <Paper className={classes.searchBarContainer}>

                                {/* Search text input */}
                                <InputBase
                                    className={classes.searchInput}
                                    type="search"
                                    placeholder={selectedVehicleNodes?.length > 0 ? "Add More" : "Search Make / Model"}
                                    name={makeModelSearchText}
                                    value={makeModelSearchText}
                                    onChange={onChangeMakeModelSearchText}
                                    onFocus={() => setShowVehiclesTree(true)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowVehiclesTree(true);
                                    }}
                                />
                                
                                {/* Selected makes */}
                                {
                                    selectedVehicleNodes?.length > 0 &&
                                    <div className={classes.selectedMakeChipContainer}>
                                        {
                                            selectedVehicleNodes.map((item: INode) => (
                                                <Chip
                                                    className={classes.selectedMakeChip}
                                                    label={item?.displayName}
                                                    onDelete={(e) => handleNodeSelected(item, e)}
                                                />
                                            ))
                                        }
                                    </div>
                                }

                                {/* Dropdown caret */}
                                <div className={classes.dropdownCaretContainer}>
                                    <ArrowDropDown 
                                        className={classes.dropdownCaret}
                                        onClick={toggleVehiclesTree} 
                                        style={showVehiclesTree ? { transform: "rotate(180deg)" } : {}}
                                    />
                                </div>
                                
                            </Paper>
                            
                            <Collapse in={showVehiclesTree}>

                                <hr style={{ width: "98%" }} />

                                {
                                    searchedVehicleNodes?.length === 0 &&
                                    <Typography className={classes.searchResultsText}>
                                        No results found
                                    </Typography>
                                }
                                {
                                    searchedVehicleNodes?.length > 0 && 
                                    <div className={classes.searchVehiclesTree}>
                                        <List className={classes.searchResultsVehiclesContainer}>
                                            {
                                                searchedVehicleNodes.map((node) => (
                                                    renderVehicleNode(node)
                                                ))
                                            }
                                        </List>
                                    </div>
                                }
                            </Collapse>
                        </div>
                    </ClickAwayListener>
                </div>

                <div className={classes.searchField}>
                    {
                        priceRanges &&
                        <div className={classes.searchFieldOutlineContainer}>
                            <RangeSlider
                                id="price_range"
                                isCurrencyVal={true}
                                reset={!isFilterApplied}
                                label="Price Range"
                                //@ts-ignore
                                range={priceRanges}
                                onChange={selectPriceRange}
                                websiteColors={websiteColors}
                            />
                        </div>
                    }
                </div>

                <div className={classes.searchField}>
                    {
                        yearRanges &&
                        <div className={classes.searchFieldOutlineContainer}>
                            <RangeSlider
                                id="year_range"
                                isCurrencyVal={false}
                                reset={!isFilterApplied}
                                label="Year"
                                //@ts-ignore
                                range={yearRanges}
                                onChange={selectYearRange}
                                websiteColors={websiteColors}
                            />
                        </div>
                    }
                </div>

                <div className={classes.searchFieldGrouped}>
                    <div className={classes.searchField}>
                        <TextField 
                            select
                            fullWidth
                            id="selectedTransmission"
                            variant="outlined"
                            label="Manual / Auto"
                            value={selectedTransmission}
                            onChange={selectTransmission}
                            SelectProps={{
                                //@ts-ignore
                                MenuProps: menuProps
                            }}
                            InputProps={{
                                classes: {
                                    root: customClasses.root,
                                    focused: customClasses.focused,
                                    notchedOutline: customClasses.notchedOutline,
                                }
                              }}
                            className={classes.customDropDown}
                        >
                            {
                                transmissions.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>

                    <div className={classes.searchField}>
                        <TextField 
                            select
                            fullWidth
                            id="selectedBodyType"
                            variant="outlined"
                            label="Body Type"
                            value={selectedBodyType}
                            onChange={selectBodyType}
                            SelectProps={{
                                //@ts-ignore
                                MenuProps: menuProps
                            }}
                            InputProps={{
                                classes: {
                                    root: customClasses.root,
                                    focused: customClasses.focused,
                                    notchedOutline: customClasses.notchedOutline,
                                }
                              }}
                            className={classes.customDropDown}
                        >
                            {
                                bodyTypes.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>
                </div>

                <div className={classes.searchFieldGrouped}>
                    <div className={classes.searchField}>
                        <TextField 
                            select
                            fullWidth
                            id="selectedMileageRange"
                            variant="outlined"
                            label="Mileage"
                            value={selectedMileageRange}
                            onChange={selectMileageRange}
                            SelectProps={{
                                //@ts-ignore
                                MenuProps: menuProps
                            }}
                            InputProps={{
                                classes: {
                                    root: customClasses.root,
                                    focused: customClasses.focused,
                                    notchedOutline: customClasses.notchedOutline,
                                }
                              }}
                            className={classes.customDropDown}
                        >
                            {
                                mileageRanges.map((item: IRange | undefined, i) => (
                                    // @ts-ignore
                                    <MenuItem key={i} value={item}>
                                        {mileageFormatter.format(item?.min || 0)} - {mileageFormatter.format(item?.max || 0)}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>

                    <div className={classes.searchField}>
                        <TextField 
                            select
                            fullWidth
                            id="selectedFuelType"
                            variant="outlined"
                            label="Fuel Type"
                            value={selectedFuelType}
                            onChange={selectFuelType}
                            SelectProps={{
                                //@ts-ignore
                                MenuProps: menuProps
                            }}
                            InputProps={{
                                classes: {
                                    root: customClasses.root,
                                    focused: customClasses.focused,
                                    notchedOutline: customClasses.notchedOutline,
                                }
                              }}
                            className={classes.customDropDown}
                        >
                            {
                                fuelTypes.map((item, i) => (
                                    <MenuItem key={i} value={item}>
                                        {item}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </div>
                </div>

                <div className={classes.searchField}>
                    {
                        instalmentRanges &&
                        <div className={classes.searchFieldOutlineContainer}>
                            <RangeSlider
                                id="instalment_range"
                                isCurrencyVal={true}
                                reset={!isFilterApplied}
                                label="Instalment"
                                //@ts-ignore
                                range={instalmentRanges}
                                onChange={selectInstalmentRange}
                                websiteColors={websiteColors}
                            />
                        </div>
                    }
                </div>

                <div className={classes.searchField}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="Keyword Search"
                        placeholder="Keyword, eg: GTI"
                        value={keywordSearchText}
                        onChange={onChangeKeywordSearchText}
                        InputProps={{
                            classes: {
                                root: customClasses.root,
                                focused: customClasses.focused,
                                notchedOutline: customClasses.notchedOutline,
                            }
                          }}
                    />
                </div>

                <div className={classes.searchField}>
                    <TextField 
                        fullWidth
                        variant="outlined"
                        label="Stock Number"
                        placeholder="Stock Number"
                        value={stockNoSearchText}
                        onChange={onChangeStockNoSearchText}
                        InputProps={{
                            classes: {
                                root: customClasses.root,
                                focused: customClasses.focused,
                                notchedOutline: customClasses.notchedOutline,
                            }
                          }}
                    />
                </div>

                <div className={classes.buttensContainer}>
                    <Button className={`${classes.transparentButton} ${classes.resetButton}`} onClick={reset}>
                        Reset
                    </Button>
                    
                    <Button className={`${classes.primaryButton} ${classes.resultsButton}`} onClick={filter}>
                        Show Results
                    </Button>
                </div>

            </div>
        </React.Fragment>
    );
};

export default VehiclesFilterV3;