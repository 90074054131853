import React, { useState, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardHeader, Grid, TextField, Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { defaultCipherList } from "constants";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
  holder: {
    borderRadius: "5px !important",
        //@ts-ignore
    backgroundColor: ({ paperColor }) => `${paperColor} !important`,
      //@ts-ignore
    boxShadow:({ paperShadowColor }) => `1px 2px 8px -1px  ${paperShadowColor}!important`,
       //@ts-ignore
    marginTop: ({ paper }) => `${paper ? "2px" : "0px"} !important`,
  },
  holderInner: {
    //@ts-ignore
    padding: ({ paper }) => `${paper ? "20px" : "0px"} !important`,
  },
  
  btn: {
    padding: "5px 30px",
    //@ts-ignore
    backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
    color: "white",
    "&:hover": {
      //@ts-ignore
      backgroundColor: ({ primaryColor }) => `${primaryColor} !important`,
      opacity: 0.8,
    },
  },
  cardPadding:{
    padding: '0px', 
    margin: '15px 0px',
  },
  inputSpacing: {
    marginBottom: '10px !important',
  }
}));

interface Props {
  personalDetails: any;
  onSubmit: Function;
  websiteColors?: any;
  paper?: boolean;
  paperColor?: string;
  paperShadowColor?: string;
  setAtiveDealerId: Function;
  dealerList?: any[];
  masterDealerid:number | null;
  defaultDealerId: any;
  rightSection?: any;
}

const PersonalDetailsForm: FC<Props> = ({
  personalDetails,
  onSubmit,
  dealerList,
  websiteColors,
  paper,
  paperColor,
  paperShadowColor,
  masterDealerid,
  defaultDealerId,
  setAtiveDealerId,
  rightSection,
}) => {

  const classes = useStyles({ ...websiteColors, paper, paperColor, paperShadowColor});
  const isDealerListAvailable = dealerList?.length && dealerList.length > 0;
  const defaultDealer = isDealerListAvailable
    ? dealerList?.find((d) => {
      return d?.dealerId === defaultDealerId;
    })
    : null;

  const [dealer, setDealer] = useState(defaultDealer);
  const initialFormValues = {
    firstName: personalDetails?.firstName || "",
    lastName: personalDetails?.lastName || "",
    phone: personalDetails?.phone || "",
    email: personalDetails?.email || "",
  };

  const phoneRegExp = /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/;

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Enter a valid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required").matches(phoneRegExp, "Phone number is not valid"),
  });

  const submitForm = (values: any) => {
    onSubmit(values);
  };
  const dealers = dealerList ? dealerList : [];
  return (
    <React.Fragment>
      <div className={classes.holder}>
        <CardHeader
          className={classes.cardPadding}
          title={"About you"}
          subheader={
            "In order to load your car we need to know a bit about you. This process will take under 5mins from start to completion."
          }
        />

<div className={classes.holderInner}>
          <Formik
            isInitialValid={false}
            initialValues={initialFormValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitForm(values);
            }}
          >
            {(props) => {
              const { values, touched, errors, isValid, handleBlur, handleChange, setFieldValue } = props;
              return (
                <Form>
          
                  <Grid item xs={12} md={4}>
                    {isDealerListAvailable === true && (
                      <TextField
                        fullWidth
                        label="Active Dealer"
                        required
                        select={dealer?.dealerId == masterDealerid ? true:false}
                        value={dealer?.name}
                        className={classes.inputSpacing}
                        onChange={(e) => {
                          handleChange(e);
                          const value = e.target.value;
                          //@ts-ignore
                          setAtiveDealerId(value?.dealerId);
                          setDealer(value);
                        }}
                        onBlur={handleBlur}
                        name="activeDealer"
                        id="activeDealer"
                      >
                        {dealers?.map((option, i) => (
                          <MenuItem key={i} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>

                  <Grid container spacing={4} direction="row">
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        value={values.firstName}
                        helperText={<>{touched.firstName ? errors.firstName : ""}</>}
                        error={touched.firstName && Boolean(errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.inputSpacing}
                      />

                      <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        value={values.lastName}
                        helperText={<>{touched.lastName ? errors.lastName : ""}</>}
                        error={touched.lastName && Boolean(errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.inputSpacing}
                      />

                      <TextField
                        id="phone"
                        name="phone"
                        label="Mobile Number"
                        variant="outlined"
                        fullWidth
                        value={values.phone}
                        helperText={<>{touched.phone ? errors.phone : ""}</>}
                        error={touched.phone && Boolean(errors.phone)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.inputSpacing}
                      />

                      <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        variant="outlined"
                        fullWidth
                        value={values.email}
                        helperText={<>{touched.email ? errors.email : ""}</>}
                        error={touched.email && Boolean(errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.inputSpacing}
                      />
                    </Grid>

                    {rightSection && (<Grid item xs={12} sm={6} md={4}>
                      {rightSection}
                    </Grid>)}

                    <Grid item xs={12} container justify="flex-end">
                      <Button className={classes.btn} variant="contained"  type="submit">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalDetailsForm;
